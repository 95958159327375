import commonMixins from './common'

export default {
  mixins: [commonMixins],
  data() {
    return {}
  },
  created() {
    this.initRenderRelyNotify()
  },
  methods: {
    // render 的值关联监听
    initRenderRelyNotify() {
      if (!this.fieldJson.renderRely) { return }
      if (!this.fieldJson.renderRelyGather || !this.fieldJson.renderRelyGather.length) { return }

      // 条件分类
      const renderRelyGather = []
      this.fieldJson.renderRelyGather.forEach(item => {
        const newIndex = renderRelyGather.length - 1
        if (item.type == 'if') {
          renderRelyGather.push([item])
        } else {
          renderRelyGather[newIndex].push(item)
        }
      })

      // this.fieldJson.options = []
      this.judgeRelyRender(renderRelyGather)

      // 添加监听关联字段变化
      this.formStore.subscribe((name) => {
        // 判断当前变动的字段是否是关联字段
        if (!this.hasRelyField(name, this.fieldJson.renderRelyGather)) { return }

        this.judgeRelyRender(renderRelyGather)
      })
    },
    // 计算关联 options
    judgeRelyRender(relyGather) {
      for (let i = 0; i < relyGather.length; i++) {
        const relyItem = relyGather[i]
        let relyRes = true

        for (let j = 0; j < relyItem.length; j++) {
          const item = relyItem[j]

          if (item.type == 'if') {
            relyRes = this.judgeByConditions(item.list)
          } else if (item.type == 'or') {
            // 如果是 或 关系，则判断之前的结果是不是 true
            // 如果是 true 就直接返回 true
            // 如果是 false 就继续判断
            if (relyRes) {
              break
            } else {
              relyRes = this.judgeByConditions(item.list)
            }
          } else {
            // 如果是 且 的关系，就判断之前的结果
            // 如果是 true 就继续往下判断
            // 如果是 false 就直接终止
            if (relyRes) {
              relyRes = this.judgeByConditions(item.list)
            } else {
              break
            }
          }
        }

        // 如果当前条件组关联结果为 true 就取当前关联组对应的options并终止循环
        if (relyRes) {
          this.data.renderType = relyItem[relyItem.length - 1].targetOptions
          break
        }
      }
    }
  }
}
