import { propEq, find } from 'ramda'
import commonMixins from './common'
import { sortStr } from '../utils/index'

export default {
  mixins: [commonMixins],
  data() {
    return {}
  },
  created() {
    this.initOptionsRelyNotify()
  },
  methods: {
    // options 的值关联监听
    initOptionsRelyNotify() {
      if (!this.fieldJson.optionsRely) { return }
      if (!this.fieldJson.optionsRelyGather || !this.fieldJson.optionsRelyGather.length) { return }
      if (!this.fieldJson.options || !this.fieldJson.options.length) { return }

      // 条件分类
      const optionsRelyGather = []
      this.fieldJson.optionsRelyGather.forEach(item => {
        const newIndex = optionsRelyGather.length - 1
        if (item.type == 'if') {
          optionsRelyGather.push([item])
        } else {
          optionsRelyGather[newIndex].push(item)
        }
      })

      // this.fieldJson.options = []
      this.judgeRelyOptions(optionsRelyGather)
      // 添加监听关联字段变化
      this.formStore.subscribe((name) => {
        // 判断当前变动的字段是否是关联字段
        if (!this.hasRelyField(name, this.fieldJson.optionsRelyGather)) { return }

        this.judgeRelyOptions(optionsRelyGather)
      })
    },
    // 计算关联 options
    judgeRelyOptions(optionsRelyGather) {
      for (let i = 0; i < optionsRelyGather.length; i++) {
        const optionsRelyItem = optionsRelyGather[i]
        this.fieldJson.options = []

        let relyRes = true

        for (let j = 0; j < optionsRelyItem.length; j++) {
          const item = optionsRelyItem[j]

          if (item.type == 'if') {
            relyRes = this.judgeByConditions(item.list)
          } else if (item.type == 'or') {
            // 如果是 或 关系，则判断之前的结果是不是 true
            // 如果是 true 就直接返回 true
            // 如果是 false 就继续判断
            if (relyRes) {
              break
            } else {
              relyRes = this.judgeByConditions(item.list)
            }
          } else {
            // 如果是 且 的关系，就判断之前的结果
            // 如果是 true 就继续往下判断
            // 如果是 false 就直接终止
            if (relyRes) {
              relyRes = this.judgeByConditions(item.list)
            } else {
              break
            }
          }
        }

        // 如果当前条件组关联结果为 true 就取当前关联组对应的options并终止循环
        if (relyRes) {
          const relyOptions = optionsRelyItem[optionsRelyItem.length - 1].targetOptions.map(value => {
            return find(propEq('value', value))(this.fieldJson.defaultOptions) || {}
          }) || []

          // 排序处理
          this.fieldJson.options = sortStr(relyOptions, this.fieldJson.sortType, `label${this.local}`, this.isNumber)

          this.fieldJson.cloneOptions = this.fieldJson.options
          break
        }
      }
    }
  }
}
