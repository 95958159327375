<template>
  <div
    v-if="show"
    :style="noteStyle"
    class="fu-min-form-note"
  >
    {{ finalNoteRelyText || finalNoteText }}
  </div>
</template>

<script>
import NoteRelyMixins from '../mixins/noteRely'

export default {
  name: 'FuFormMinItemNote',
  mixins: [NoteRelyMixins],
  props: {
    lang: { type: String, default: 'en' },
    fieldJson: { type: Object, default: () => {} }
  },
  data() {
    return {
      finalNoteRelyText: null
    }
  },
  computed: {
    finalNoteText() {
      return this.lang == 'en' ? this.fieldJson.noteText : this.fieldJson.noteTextLocal || this.fieldJson.noteText
    },
    show() {
      return this.fieldJson && this.fieldJson.note && this.finalNoteText
    },
    noteStyle() {
      if (!this.fieldJson.noteStyle) { return {} }

      const { fontSize, lineHeight } = this.fieldJson.noteStyle
      const assignStyle = {}
      if (fontSize) {
        assignStyle.fontSize = `${fontSize}px`
      }
      if (lineHeight) {
        assignStyle.lineHeight = `${lineHeight}px`
      }

      return {
        ...this.fieldJson.noteStyle,
        ...assignStyle
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
