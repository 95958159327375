// h5 上传处理逻辑
import { getUploadHandleLs } from '../utils'

export default {
  data() {
    return {

      isShowPopup: false,
      uploadHandleLs: [],
      isMoreTypeUpload: false,
      currentType: '',
      accept: '',
      capture: '',

      h5UploaderProps: {}
    }
  },

  methods: {
    // h5 上传的初始方法
    uploadInH5() {
      this.uploadH5V2()

      // // 首先判断配置是旧版 还是 新版
      // if (this.isV2) { // 新版逻辑
      //   this.uploadH5V2()
      // } else { // 旧版逻辑
      //   this.uploadH5V1()
      // }
    },

    // V2 版本处理
    // V2 版本可以上传 图片、视频 和 文件
    uploadH5V2() {
      const { uploadType } = this.fieldJson
      // 获取当前支持的上传文件类型
      this.uploadHandleLs = getUploadHandleLs(uploadType)

      // 如果支持多种文件上传
      // 则需要先出一个弹窗让用户选择需要上传什么类型的文件
      if (this.uploadHandleLs.length > 1) {
        this.isShowPopup = true
      } else { // 单类型文件上传
        this.currentType = this.uploadHandleLs[0]?.value

        // 处理单个类型上传
        this.uploadV2Single()
      }
    },
    // V1 版本处理
    // V1 版本只能上传 图片 和 视频
    uploadH5V1() {
      const { uploadSource = 3 } = this.fieldJson
      if (uploadSource == 3) {
        this.isShowPopup = true

        this.uploadHandleLs = [
          { name: 'Camera', value: 'camera', icon: 'photograph' },
          { name: 'Album', value: 'album', icon: 'photo' }
        ]
      } else if (uploadSource == 1) { // 仅拍照
        this.h5UploaderProps = { capture: this.isVideo ? 'camcorder' : 'camera' }
        // this.accept = this.isVideo ? 'video/*' : 'image/*'
      } else { // 仅相册
        this.h5UploaderProps = { multiple: true }
      }
    },

    // 单次上传操作
    uploadV2Single() {
      const { uploadSource } = this.fieldJson
      if (uploadSource == 3) { // 支持 拍照 和 相册
        // 这里需要多判断一层  如鬼是文件的话 只能从手机中选择
        // 不可能会有唤起摄像头的逻辑
        if (this.currentType == 'file') {
          this.uploadV2SingleAction('album')
        } else {
          this.uploadHandleLs = [
            { name: 'Camera', value: 'camera', icon: 'photograph' },
            { name: 'Album', value: 'album', icon: 'photo' }
          ]
          this.isShowPopup = true
        }
      } else if (uploadSource == 1) { // 仅拍照
        this.uploadV2SingleAction('camera')
      } else { // 仅相册
        this.uploadV2SingleAction('album')
      }
    },

    // 上传面板的点击处理
    // 会有两种情况  一个是文件类型选择  一个是上传类型选择
    onUploadHandleClick(item) {
      const type = item.value

      // 处理具体上传方式
      if (['camera', 'album'].includes(type)) {
        this.uploadV2SingleAction(type)
      } else { // 处理文件类型上传
        // 缓存当前文件类型
        this.currentType = type
        this.uploadV2Single()
      }
    },

    // 单类型文件 单次操作
    // type ==> 拍照 还是 相册
    uploadV2SingleAction(type) {
      const { uploadType } = this.fieldJson

      // , this.currentType == 'video' ? 'camcorder' : 'camera'
      this.h5UploaderProps = type == 'album' ? { multiple: true } : { capture: 'environment' }

      this.accept = this.currentType == 'file' ? uploadType.filter(item => ['pdf', 'xlsx', 'ppt', 'doc'].includes(item)).map(type => {
        return this.fileTypeMap[type] || `.${type}`
      }).flat().join(',') : ''

      this.$nextTick(() => {
        this.isShowPopup = false
        this.$refs[this.uploadRef].chooseFile()
      })
    }
  }
}
