import { clone, type, ifElse, F, forEach, path, equals, all } from 'ramda'

// 工具函数模块
export function isString(name) {
  return type(name) === 'String'
}

export function isArray(name) {
  return type(name) === 'Array'
}

export function isArrayAndNotNilArray(name) {
  if (type(name) !== 'Array') return false
  return name.length !== 0
}

export function isUndefined(name) {
  return type(name) === 'Undefined'
}

export function isObject(name) {
  return type(name) === 'Object'
}

export function isStrOrArray(name) {
  return isString(name) || isArray(name)
}

// 字符串转数组的
export function strToArray(name) {
  if (isString(name)) return [name]
  if (isArray(name)) return name
  throw new Error(`${name} params need to an Array or String`)
}

export const returnNameOrTrue = returnName => name => {
  return returnName ? name : true
}

export function isNameType(name, returnName = false) {
  return ifElse(
    isStrOrArray,
    returnNameOrTrue(returnName),
    F
  )(name)
}

export function checkSchema(schema) {
  ifElse(
    isArrayAndNotNilArray,
    forEach(checkFormItems),
    () => { throw new Error('formItems property of schema need to an Array') }
  )(path(['formItems'], schema))
}

export function checkFormItems(item) {
  if (!all(equals(true))([isObject(item), isNameType(item.name)])) {
    throw new Error('please check whether formItems field of schema meet the specifications')
  }
}

// function setFormReduce(acc, item) {
//   if (!isUndefined(item.value)) {
//     acc = assocPath(strToArray(item.name), item.value, acc)
//   }
//   return acc
// }

// function setSchemaToValues(initialSchema) {
//   return pipe(
//     path(['formItems']),
//     reduce(setFormReduce, {})
//   )(initialSchema)
// }

// const warningFunc = () => {
//   console.warn(
//     'Please make sure to call the getInternalHooks correctly'
//   )
// }

// 这个函数是用来提取对象的路径的比如说：
// pickPaths({a: 2, c: 3 })
// => [[{path: 'a', value: 2 }], [{ path: 'c', value: 3 }]]
// pickPaths({ b:[ { a : 1 } ] )
// => [[ { path: [ "b",0,"a"], value: 1 }]]
export function pickPaths(root, collects = [], resultPaths = []) {
  function dfs(root, collects) {
    if (isObject(root)) {
      return Object.keys(root).map((item) => {
        const newCollect = clone(collects)
        newCollect.push(item)
        return dfs(root[item], newCollect)
      })
    }
    if (isArray(root)) {
      return root.map((item, index) => {
        const newCollect = clone(collects)
        newCollect.push(index)
        return dfs(item, newCollect)
      })
    }
    return resultPaths.push({ path: collects, value: root })
  }

  dfs(root, collects)
  return resultPaths
}

