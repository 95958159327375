<template>
  <div v-if="ifRender" class="fu-min-form-item">
    <Field
      class="no-border"
      :label="label"
      :name="data.name + path"
      :rules="rules"
      v-bind="fieldJson"
    >
      <!-- 自定义 label -->
      <template v-if="fieldJson.tip" slot="label">
        <FuFormLabel
          :parent="parent"
          :label="label"
          :field-json="fieldJson"
        />
      </template>

      <template #input>
        <div style="width: 100%">
          <Slider
            v-model="currentValue"
            :range="fieldJson.range"
            :step="fieldJson.step || 1"
            :bar-height="fieldJson.barHeight"
            active-color="#ee0a24"
            :min="dynamicConfig.min || data.schema.minimum"
            :max="dynamicConfig.max || data.schema.maximum"
          />

          <p class="fu-min-form-slider-values">{{ numericDisplayValue }} {{ $store.state.base ? $store.state.base.currency : 'VND' }}</p>
        </div>
      </template>
    </Field>

    <FuFormNote
      v-if="!parent.isFormReadOnly"
      :lang="parent.lang"
      :field-json="fieldJson"
    />
  </div>
</template>

<script>
import { Slider } from 'vant'
import commonMixins from '../mixins/materials'
import displayMixins from '../mixins/display'
import numericMixins from '../mixins/numeric'

export default {
  name: 'FormItemInput',
  components: { Slider },
  directives: { },
  mixins: [commonMixins, displayMixins, numericMixins],
  data() {
    return {

    }
  }
}
</script>

<style lang="scss">
.fu-min-form-slider-values {
  margin-top: 15px;
  text-align: right;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12.5px;
  line-height: 15px;
  text-align: right;

  color: #4B4B4B;
}
</style>
