import request from '../utils/request'
import { sortStr } from '../utils/index'

export default {
  data() {
    return {
      loading: false
    }
  },
  mounted() {
    this.initOptionsSourceNotify()
  },
  methods: {
    // options 的值来源监听
    initOptionsSourceNotify() {
      // 关联获取 options
      if (!this.fieldJson.show) { return }
      if (this.fieldJson.optionsSource == 1) {
        this.initOptionByStatic()
        return
      }

      if (this.fieldJson.optionsSource == 2) { // 码表
        this.initOptionsSourceByCode()
      } else if (this.fieldJson.optionsSource == 3) { // 自定义接口
        this.initOptionsSourceByOrigin()
      }
    },

    // 静态Options补全
    initOptionByStatic() {
      const { options, optionsUnit, optionsUnitLabel, optionsUnitLabelLocal } = this.fieldJson

      if (!optionsUnit) { return }

      this.fieldJson.options = options.map(item => {
        const { label, labelLocal } = item
        return {
          ...item,
          label: `${label} ${optionsUnitLabel}`,
          labelLocal: `${labelLocal} ${optionsUnitLabelLocal}`
        }
      })
    },

    // 通过码表获取 options
    initOptionsSourceByCode() {
      if (!this.fieldJson.optionsHasExtraRelyParams) { return }
      // 初始化的时候判断取值一次
      this.getOptions()

      // 如果关联参数是空则没必要监听
      if (!this.fieldJson.optionsOriginParams || !this.fieldJson.optionsOriginParams.length) {
        return
      }

      // 添加监听关联字段变化
      this.formStore.subscribe((name) => {
        // 判断当前变动的字段是否是关联字段
        if (!this.hasRelyParams(name, this.fieldJson.optionsOriginParams)) { return }

        this.fieldJson.options = []
        // this.currentValue = ''

        this.getOptions()
      })
    },

    getOptions() {
      if (!['Select', 'Radio', 'Checkbox'].includes(this.data.renderType)) { return }

      const optionsOriginParams = this.fieldJson.optionsOriginParams || []
      const params = this.getParams(optionsOriginParams).whereParam
      const finalParams = Array.isArray(params) ? params : [params]

      // 所有参数都有值的情况下才会查询
      if (this.isAllParamsValued(finalParams, optionsOriginParams)) {
        this.getOptionByCode(finalParams)
      }
    },
    // 通过自定义接口获取 options
    initOptionsSourceByOrigin() {
      if (!this.fieldJson.optionsOriginUrl) { return }

      // 获取参数配置
      const originParams = this.fieldJson.optionsOriginParams
      // 获取参数
      const params = this.getParams(originParams)

      if (!this.hasRelyFieldFromParams(originParams)) { // 没有关联字段则直接查询
        this.getOptionsByOrigin(params)
      } else { // 有关联字段则添加监听
        // 初始化的时候如果参数有值则直接查询一次
        if (this.isAllParamsValued(params, originParams)) {
          this.getOptionsByOrigin(params)
        }

        this.formStore.subscribe((name) => {
          // 判断当前变动的字段是否是关联字段
          if (!this.hasRelyParams(name, originParams)) { return }
          // 获取参数
          const currentParams = this.getParams(originParams)

          this.fieldJson.options = []

          // 所有参数都有值的情况下才会查询
          if (this.isAllParamsValued(currentParams, originParams)) {
            this.getOptionsByOrigin(currentParams)
          }
        })
      }
    },

    // 通过接口获取options
    async getOptionsByOrigin(params = {}) {
      this.fieldJson.options = []

      const url = `/${this.fieldJson.optionsOriginUrl}`

      this.loading = true
      const res = await request({
        url: url,
        ...this.fieldJson.optionsOriginCfg,
        data: params
      })
      this.loading = false

      if (!res) { return }

      const originOptions = Array.isArray(res) ? res : res.data || []

      const finalOptions = originOptions.map(item => {
        return {
          label: item.text,
          labelLocal: item.localText || item.textLocal || item.viText || item.text,
          value: item.code
        }
      })

      // 排序处理
      this.fieldJson.options = sortStr(finalOptions, this.fieldJson.sortType, `label${this.local}`, this.isNumber)

      // IDP-9112 判断是否配置了特殊默认值
      this.calculateDefaultValue(this.fieldJson)

      this.fieldJson.cloneOptions = this.fieldJson.options
    },

    // 通过 码表code 获取 options
    async getOptionByCode(whereParam = []) {
      const url = `/pro/pfm/codetable/list`

      this.loading = true
      const res = await request({
        url: url,
        method: 'post',
        data: {
          codeTable: this.fieldJson.optionsCode,
          whereParam
        }
      })
      this.loading = false

      if (!res || !res.length) { return }

      this.fieldJson.options = res.map(item => {
        return {
          label: item.value,
          labelLocal: item.valueLocal,
          value: item.code
        }
      })

      this.fieldJson.cloneOptions = this.fieldJson.options
    },

    // 判断参数里是否有关联字段
    hasRelyFieldFromParams(params) {
      return params.some(item => {
        return item.source == 1
      })
    }

  }
}
