import request from '../utils/request'
import commonMixins from './common'
import { clone } from 'ramda'
import { getAssignDate } from '../utils/index'

export default {
  mixins: [commonMixins],
  methods: {
    // 默认值的监听
    initDefaultValueNotify() {
      const { show, valueNotRelatedShow, defaultValueSource } = this.fieldJson

      // 关联获取 options
      if (!show && !valueNotRelatedShow) { return }
      if (![2, 3, 5, '2', '3', '5'].includes(defaultValueSource)) { return }

      if (defaultValueSource == 2) { // 关联默认值
        this.initDefaultValueNotifyNByRely()
      } else if (defaultValueSource == 3) { // 接口默认值
        this.initDefaultValueNotifyNByOrigin()
      } else if (defaultValueSource == 5) { // 高级配置 计算公式
        this.initDefaultValueNotifyNByAdvanced()
      }
    },

    // 通过关联字段获取默认值
    initDefaultValueNotifyNByRely() {
      const { defaultValueRelyGather } = this.fieldJson

      // 相关配置项为空则终止
      if (!defaultValueRelyGather || !defaultValueRelyGather.length) { return }

      // 条件分类
      const finalDefaultValueRelyGather = this.getFinalRelyGather(defaultValueRelyGather)

      // 初始化的是先计算一次默认值
      this.judgeRelyDefaultValue(finalDefaultValueRelyGather)

      // 添加监听关联字段变化
      this.formStore.subscribe((name) => {
        // 判断当前变动的字段是否是关联字段
        if (!this.hasRelyField(name, defaultValueRelyGather)) { return }

        this.judgeRelyDefaultValue(finalDefaultValueRelyGather)
      })
    },

    // 计算关联 默认值
    judgeRelyDefaultValue(relyGather = []) {
      // 获取匹配条件的关联值
      const judgeRelyRes = this.judgeRely(relyGather)

      // 非空判断
      // 因为返回值有可能是 0 或 空字符串这种，所以这里要判断是否等于 undefined
      if (judgeRelyRes !== undefined) {
        this.currentValue = judgeRelyRes
      }
    },

    // 通过接口获取默认值
    initDefaultValueNotifyNByOrigin() {
      const { defaultValueOriginUrl, defaultValueOriginParams = [] } = this.fieldJson

      // 相关配置项为空则终止
      if (!defaultValueOriginUrl) { return }

      const originParams = defaultValueOriginParams
      // 添加监听关联字段变化
      this.formStore.subscribe((name) => {
        // 判断当前变动的字段是否是关联字段
        if (!this.hasRelyParams(name, originParams)) { return }

        this.currentValue = ''

        // 获取配置的参数
        const params = this.getParams(originParams)
        // 所有参数都有值的情况下才会查询
        if (this.isAllParamsValued(params, originParams)) {
          this.getDefaultValue(params)
        }
      })
    },

    // 通过配置的计算公式计算
    initDefaultValueNotifyNByAdvanced() {
      const { defaultValueAdvancedGather } = this.fieldJson

      // 相关配置项为空则终止
      if (!defaultValueAdvancedGather || !defaultValueAdvancedGather.length) { return }

      // 初始化的是先计算一次默认值
      this.calculateDefaultValue(this.fieldJson)

      // 添加监听关联字段变化
      this.formStore.subscribe((name) => {
        // 判断当前变动的字段是否是关联字段
        if (!defaultValueAdvancedGather.some(item => {
          if (item.type == 5) { return false }
          return this.formStore.isSamePath(name, item.path)
        })) { return }

        this.calculateDefaultValue(this.fieldJson)
      })
    },

    // 计算默认值
    calculateDefaultValue(fieldJson) {
      const { defaultValueAdvancedGather } = fieldJson || {}
      if (!defaultValueAdvancedGather || !defaultValueAdvancedGather.length) { return }

      const res = defaultValueAdvancedGather.reduce((prev, item) => {
        const { path, type, value } = item

        // IDP-9112 特殊处理
        if (type == 5 && value && !path) {
          const { options = [] } = fieldJson

          this.currentValue = !options.length ? 0 : options.reduce((prev, item) => {
            if (prev == 'init') { return item.value }

            if (value == 'min') {
              return prev * 1 > item.value * 1 ? item.value : prev
            } else if (value == 'max') {
              return prev * 1 < item.value * 1 ? item.value : prev
            }

            return ''
          }, 'init')
          return
        }

        const pathValue = this.formStore.getFieldValue(path)
        if (pathValue === undefined || type == 5) { return }

        const copyPath = clone(path)
        const pathKey = path[path.length - 1]
        const prevPath = copyPath.splice(0, copyPath.length - 1)
        const unitPath = [...prevPath, `${pathKey}Unit`]
        const pathValueUnit = this.formStore.getFieldValue(unitPath)
        // 这里需要转换一下
        const finalUnit = pathValueUnit == 1 ? 3 : pathValueUnit == 3 ? 1 : pathValueUnit

        const isPrevObject = typeof prev == 'object'

        // 根据配置的逻辑来判断
        switch (type) {
          // +
          case 1:
            // TODO: 临时解决方案
            if (isPrevObject) {
              const assignDate = new Date(getAssignDate(prev.value, prev.unit, pathValue)) - 1000

              return assignDate
            } else {
              if (finalUnit) {
                const assignDate = new Date(getAssignDate(pathValue, finalUnit, prev)) - 1000

                return assignDate
              } else {
                return ((prev * 1) + (pathValue * 1))
              }
            }
            // -
          case 2:
            return (prev - pathValue)
            // x
          case 3:
            return (prev * pathValue)
            // %
          case 4:
            return (prev / pathValue)
          default:
            return finalUnit ? {
              value: pathValue,
              unit: finalUnit
            } : pathValue
        }
      }, 0)

      if (isNaN(res) || res == undefined) { return }

      this.currentValue = res
    },

    async getDefaultValue(params = {}) {
      const { defaultValueOriginUrl, defaultValueOriginCfg, defaultValueResponseKey } = this.fieldJson
      const url = `/${defaultValueOriginUrl}`

      this.loading = true
      const res = await request({
        url,
        ...defaultValueOriginCfg,
        data: params
      })
      this.loading = false

      if (!res) { return }

      // 是否配置了指定字段
      // 如有配置则取制定字段的值
      if (defaultValueResponseKey) {
        const path = defaultValueResponseKey.split('.')
        // this.currentValue = res[defaultValueResponseKey]
        this.currentValue = this.getValueByPath(res, path)
      } else {
        this.currentValue = res.avgPrice
      }
    },

    getValueByPath(obj, path = []) {
      return path.reduce((prev, item) => {
        if (typeof prev != 'object') { return prev }
        return prev[item]
      }, obj)
    },

    // 组合参数
    getParams(params) {
      const resParams = {}

      params.forEach(item => {
        if (item.source == 1) {
          const value = this.formStore.getFieldValue(item.value)
          const { expandKey } = item
          const finalValue = typeof value == 'object' && expandKey ? value[expandKey] : value

          this.setParamsItem(resParams, item.name, finalValue)
        } else if (item.source == 4) { // 扩展Key
          const value = this.parent?.extendDatas[item.name] || ''
          this.setParamsItem(resParams, item.name, value)
        } else {
          this.setParamsItem(resParams, item.name, item.value)
        }
      })

      return resParams
    },
    // 配置每项参数
    setParamsItem(params, key, value) {
      if (params[key]) {
        params[key] = Array.isArray(params[key]) ? [...params[key], value] : [params[key], value]
      } else {
        params[key] = value
      }
    },
    // 判断配置的关联数据是否有当前变动字段
    hasRelyParams(path, params) {
      return params.some(item => {
        return item.source == 1 && this.formStore.isSamePath(path, item.value)
      })
    },
    // 判断所有参数是否都是有效值
    isAllParamsValued(params, paramsConfig = []) {
      for (let i = 0; i < paramsConfig.length; i++) {
        const item = paramsConfig[i]
        const { required, name } = item

        // 必填
        if (required === true || required === undefined) {
          if (Array.isArray(params)) {
            if (!params[i] && params[i] !== 0) { return false }
          } else {
            if (!params[name] && name[name] !== 0) { return false }
          }
        }
      }

      return true
    }
  }
}
