import Dao from '@/utils/dao'
import { Session } from '@/utils/storage'

export const isRegExp = (v) => {
  // eslint-disable-next-line no-eval
  return eval(v) instanceof RegExp
  // return Object.prototype.toString.call(v) === '[object RegExp]'
}

export function formatDate(time, cFormat) {
  const tenantLocal = Dao.get('tenantLocal') || {}
  const fuseSetting = Dao.get('fuseSetting') || Dao.get('fuseIdSetting') || Dao.get('fuseVnSetting') || {}
  const timeZone = tenantLocal.timezone || fuseSetting.timeZone || 7
  const offset_GMT = new Date().getTimezoneOffset()

  if (arguments.length === 0) {
    return null
  }
  if (!time) return '-'

  const format = cFormat || '{d}/{m}/{y}'
  let date

  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (time.toString().length === 10) && (/^[0-9]+$/.test(time))) {
      time = parseInt(time) * 1000
    }
    if ((typeof time === 'string') && (time.toString().length === 13) && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    if ((typeof time === 'string') && time.indexOf('-') > -1) {
      date = new Date(time)
    } else {
      date = new Date(time + offset_GMT * 60 * 1000 + timeZone * 60 * 60 * 1000)
    }
  }

  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }

  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][value] }
    return value.toString().padStart(2, '0')
  })
  return CHECKIFVALUEISUNDEFINED(time_str) || ''
}

// 判断键值是否不为undefined
function CHECKIFVALUEISUNDEFINED(value) {
  return value === undefined ? '-' : value
}

/**
 * 判断是否是闰年
 * @params {year} 需要判断的年份
 * @return {boolean}
 */
export function isLeapYear(year) {
  return (year % 4 == 0) && (year % 100 != 0 || year % 400 == 0)
}

/**
  * 时间戳转指定时区的格式时间
  * @params date {timestamp} 时间戳 - 1564704000000格式
  * @return timeZone {string} 时区字符串 - '+04:00'、'+00:00'格式
  * @returns {string} - '2015-01-01 12:12:12'格式
  */
export function getAnyFormatDate(timestamp, timeZone, formatStr = '{d}/{m}/{y}') {
  const sameTimeLocalTimeStamp = timestamp - 0 + new Date(timestamp).getTimezoneOffset() * 60 * 1000 + getOffsetMinute(timeZone) * 60 * 1000
  const targetTime = new Date(sameTimeLocalTimeStamp)

  const formatObj = {
    y: targetTime.getFullYear(),
    m: targetTime.getMonth() + 1,
    d: targetTime.getDate(),
    h: targetTime.getHours(),
    i: targetTime.getMinutes(),
    s: targetTime.getSeconds(),
    a: targetTime.getDay()
  }

  const time_str = formatStr.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][value] }
    return value.toString().padStart(2, '0')
  })

  return time_str
}

/**
  * 格式时间转指定时区的时间戳
  * @date {str} 时间字符串 - '2015-01-01'、'2015-01-01 12'、 '2015-01-01 12:11'、'2015-01-01 12:12:12'格式
  * @timeZone {str} 时区字符串 - '+04:00'、'+00:00'格式
  * @returns {number} 按时区转换的时间戳
  */
export function getAnyTimestamp(date, timeZone) {
  // 判断是否是格式时间
  if (!/^\d{5,}$/.test(date)) {
    // 非标准格式时间转成标准格式时间
    // 标准格式 ==> 2019/01/02 00:00:00
    const normDate = String(date)
      .replace(/^([0-9]{4})$/, '$1-01-01 00:00:00')
      .replace(/^([0-9]{4}-[0-9]{2})$/, '$1-01 00:00:00')
      .replace(/^([0-9]{4}-[0-9]{2}-[0-9]{2})$/, '$1 00:00:00')
      .replace(/^([0-9]{4}-[0-9]{2}-[0-9]{2}\s[0-9]{2})$/, '$1:00:00')
      .replace(/-/g, '/')

    // 先转成本地时区的时间戳
    const localTimestamp = new Date(normDate).getTime()

    // 最后先算0时区的时间戳再减指定时区的时间戳
    return localTimestamp - new Date(date.replace(/-/g, '/')).getTimezoneOffset() * 60 * 1000 - getOffsetMinute(timeZone) * 60 * 1000
  }

  // 非格式时间则原封不动返回
  return date
}

// 计算以分钟为单位的时区gap，例如 '+04:00'被转换为240
function getOffsetMinute(timeZone) {
  // 这里使用较为严格的格式验证限制，缺少首位0和正负号视为非法格式
  if (!/^[+-][0-9]{2}:((0[0-9])|([1-5][0-9]))$/.test(timeZone)) {
    return 0
  }
  const time = timeZone.split(':')

  // time[0]本身带正负号不需要加，time[1]不带需要加。
  if (timeZone[0] === '-') {
    return parseInt(time[0]) * 60 - parseInt(time[1].slice(0, 2))
  } else {
    return parseInt(time[0]) * 60 + parseInt(time[1].slice(0, 2))
  }
}

/**
 * 内部的时区转标准时区格式
 * @timezone 时区
 * @return {string} 标准格式时区
 */
export function getNormTimeZone(timezone = 7) {
  const timegapHour = String(timezone) // 获取小时
    .replace(/^([+-]?)(\d)$/, '$1' + 0 + '$2') // 单数小时补0
    .replace(/^(\d+)$/, '+' + '$1') // 整数小时补+号
  // const timegapMinute = String(Math.abs(new Date(date).getTimezoneOffset() % 60)) // 获取分钟
    // .replace(/^(\d)$/, 0 + '$1') // 单数分钟补0
  return `${timegapHour}:00`
}

/**
 * 设置偏移指定天数的时间
 * @params targetDate 偏移的目标时间 为空则取当前时间
 * @params config 偏移的一些基础配置
 * @params config.value 偏移值
 * @params config.unit 偏移值单位
 * @params config.year 偏移单位是年的情况下 一年的计算方式
 * @params config.time 偏移后的具体时间
 * @params config.t 最后的计算是否要做额外的偏移
 */
export function setAssignDate(targetDate, config = {
  value: 0, // 指定偏移的日期
  unit: 1, // 1 => year, 2 => mouth, 3 => day
  year: 1, // 1 => 365天, 2 => 自然年
  time: '23:59:59', // 偏移后的具体时间
  t: 1, // 1 => T+0 , 2 => T+1,
  isOverFourPM: false // 泰国专门的需求处理，超过泰国时区的5点 则只能从第二点开始
}) {
  const { value, unit, time = '23:59:59', year = 2, t = 1, isOverFourPM = false } = config
  const [H, M, S] = time.split(':')
  const { y, m, d } = getTargetDate(targetDate)
  const intValue = value * 1
  const tDay = t == 1 ? -1 : 0
  const finalDay = isOverFourPM ? tDay * 1 + 1 : tDay

  // 当偏移单位是年，而且一年是固定365天的情况下
  // 直接转成 天数相加
  if (unit == 1 && year == 1) {
    return new Date(y, m, d + (intValue * 365) + finalDay, H, M, S)
  } else {
    if (unit == 1) { // year
      return new Date(y + intValue, m, d + finalDay, H, M, S)
    } else if (unit == 2) { // mouth
      return new Date(y, m + intValue, d + finalDay, H, M, S)
    } if (unit == 3) { // day
      return new Date(y, m, d + intValue + finalDay, H, M, S)
    }
  }
}

// 获取时间对象的年月日
export function getTargetDate(targetDate) {
  // console.log(1111)
  // IDP-6732 - Date Picker in Quotation Form should use service time
  const serverDate = Session.get('serverDate')
  // 获取最新服务器时间
  const lastHttpDate = serverDate || Dao.get('LastHttpDate')
  const currentDate = targetDate ? new Date(targetDate) : lastHttpDate ? new Date(lastHttpDate) : new Date()

  return {
    y: currentDate.getFullYear(),
    m: currentDate.getMonth(),
    d: currentDate.getDate(),
    H: currentDate.getHours(),
    M: currentDate.getMinutes(),
    S: currentDate.getSeconds()
  }
}

export function getAssignDate(date, unit, targetDate) {
  const { y, m, d } = getTargetDate(targetDate)

  const dateToInt = parseInt(date)

  if (unit == 1) { // year
    return new Date(y + dateToInt, m, d)
  } else if (unit == 2) { // mouth
    return new Date(y, m + dateToInt, d)
  } if (unit == 3) { // day
    return new Date(y, m, d + dateToInt)
  }
}

// 数组升降序
export function sortStr(arr, type, key, isNumber) {
  if (!Array.isArray(arr)) { return arr }
  if (!type) { return arr }
  if (type && !['descending', 'ascending'].includes(type)) { return arr }

  const originalSort = key ? arr.sort((a, b) => {
    var val1 = isNumber ? parseInt(a[key]) : a[key]
    var val2 = isNumber ? parseInt(b[key]) : b[key]

    if (val1 < val2) {
      return -1
    } else if (val1 > val2) {
      return 1
    } else {
      return 0
    }
  }) : arr.sort()

  // 升序
  if (type === 'ascending') {
    return originalSort
  }
  // 降序
  if (type === 'descending') {
    return originalSort.reverse()
  }

  return arr
}

export const getUploadHandleLs = (uploadType) => {
  if (!uploadType || !Array.isArray(uploadType)) { return [] }
  if (!uploadType.length) { return [] }

  const handleLs = []

  if (uploadType.includes('img')) {
    handleLs.push({ name: 'Img', value: 'img' })
  }
  if (uploadType.includes('video')) {
    handleLs.push({ name: 'Video', value: 'video' })
  }
  if (uploadType.some(type => ['pdf', 'xlsx', 'doc'].includes(type))) {
    handleLs.push({ name: 'File', value: 'file' })
  }

  return handleLs
}

export const getVideoPlayerInfo = (file) => {
  return new Promise(resolve => {
    const videoElement = document.createElement('video')
    videoElement.src = URL.createObjectURL(file)
    videoElement.addEventListener('loadedmetadata', function () {
      resolve({
        duration: videoElement.duration,
        width: videoElement.videoWidth,
        height: videoElement.videoHeight
      })
    })
  })
}

