<template>
  <div v-if="ifRender" class="fu-min-form-item">
    <!-- autoLinking模式 -->
    <!-- 自动计算结束时间 -->
    <!-- fixed模式只单纯的显示 period 时间 -->
    <!-- options模式则以下拉的方式让用户去选择具体的年限 -->
    <template v-if="fieldJson.autoLinking && !parent.isFormReadOnly">
      <Field
        readonly
        clickable
        :value="coveragePeriodShowValue"
        :label="periodText[parent.lang]"
        :placeholder="placeholder"
        :right-icon="isAutoLinkOfFixed ? '' : 'arrow-down'"
        :disabled="isAutoLinkOfFixed"
        @click="isShowCoveragePeriod = isAutoLinkOfOptions"
      />
      <Popup v-model="isShowCoveragePeriod" position="bottom">
        <Picker
          v-model="coveragePeriod"
          show-toolbar
          :value-key="optionsLabel"
          :columns="coveragePeriodOptions"

          :confirm-button-text="$t('confirm')"
          :cancel-button-text="$t('cancel')"
          @cancel="isShowCoveragePeriod = false"
          @confirm="onCoveragePeriodChange"
        />
      </Popup>
    </template>

    <!-- 正常选择时间 -->
    <Field
      v-if="!(fieldJson.autoLinking && fieldJson.hiddenDateElByAutoLink)"
      readonly
      clickable
      right-icon="notes-o"
      :value="fieldShowValue"
      :label="label"
      :placeholder="placeholder"
      :name="data.name + path"
      :rules="rules"
      v-bind="fieldJson"
      @click="onClickFieldForPopup"
    >
      <!-- 自定义 label -->
      <template v-if="fieldJson.tip" slot="label">
        <FuFormLabel
          :parent="parent"
          :label="label"
          :field-json="fieldJson"
        />
      </template>
    </Field>

    <Popup
      v-model="visibleForPopup"
      position="bottom"
      round
      get-container="body"
    >
      <DatetimePicker
        v-if="!fieldJson.autoLinking"
        v-model="currentDate"
        :type="fieldJson.dateType"
        v-bind="dateProps"
        :confirm-button-text="$t('confirm')"
        :cancel-button-text="$t('cancel')"
        :columns-order="['day', 'month', 'year']"
        @confirm="onConfirm"
        @cancel="visibleForPopup = false"
      />
    </Popup>

    <FuFormNote
      v-if="!parent.isFormReadOnly"
      :lang="parent.lang"
      :field-json="fieldJson"
    />
  </div>
</template>

<script>
import commonMixins from '../mixins/materials'
import displayMixins from '../mixins/display'
import { getNormTimeZone, getAnyTimestamp, getAnyFormatDate, isLeapYear, setAssignDate, getAssignDate, getTargetDate } from '../utils/index'
import { find, propEq } from 'ramda'
import { Session } from '@/utils/storage'

const unitText = {
  en: {
    day: 'Day',
    month: 'Month',
    year: 'Year'
  },
  vi: {
    day: 'ngày',
    month: 'tháng',
    year: 'năm'
  },
  th: {
    day: 'วัน',
    month: 'เดือน',
    year: 'ปี'
  }
}

export default {
  name: 'FormItemDate',
  mixins: [commonMixins, displayMixins],
  data() {
    return {
      // autoLinking模式下 options下拉的弹窗控制变量
      isShowCoveragePeriod: false,
      coveragePeriod: '',
      // 定义监控字段的路径
      autoLinkFieldPath: ['insuranceInfo', 'effectiveDate'],
      periodText: {
        en: 'Coverage Period',
        vi: 'Thời hạn bảo hiểm',
        th: 'ระยะเวลาคุ้มครอง'
      },

      currentDate: new Date()
    }
  },
  computed: {
    unitMap() {
      return {
        1: unitText[this.parent.lang].day,
        2: unitText[this.parent.lang].month,
        3: unitText[this.parent.lang].year
      }
    },
    fieldShowValue() {
      const { zone = 7 } = Session.get('appInfo') || {}
      const timezone = getNormTimeZone(zone)
      return this.currentValue ? getAnyFormatDate(this.currentValue, timezone) : ''
    },
    isDateTime() {
      return ['date', 'datetime', 'year-month', 'datehour'].includes(this.fieldJson.dateType)
    },
    dateProps() {
      if (this.isDateTime) {
        const dateProps = {}

        const { minDate, minDateUnit, maxDate, maxDateUnit, isMinDateRely, isMaxDateRely, minDateRelyPath = [], maxDateRelyPath = [] } = this.fieldJson

        if (isMinDateRely) { // 最小时间关联其他字段
          const minDate = this.formStore.getFieldValue(minDateRelyPath)

          // 这里需要校验如果依赖的最小时间字段变化了并且大于当前时间，则需要把当前时间置空并重新选择
          if (!!this.currentValue && !!minDate && (minDate > this.currentValue)) {
            this.setCurrentValueEmpty()
          }

          dateProps.minDate = new Date(minDate)
        } else {
          // IDP-7377
          // 泰国特有逻辑，在没有配置最小时间的情况下 effective date 字段可选的最小时间
          // 如果是 16:00 之前则可以选当天时间
          // 如果是 16:00 之后则只能选第二天
          // ps: 这个 16:00 是服务器时间
          const { region } = Session.get('appInfo') || {}
          const isTH = region == 'TH'
          const serverDate = Session.get('serverDate')
          const currentDate = serverDate ? new Date(serverDate) : new Date()
          const isOverFourPM = currentDate.getHours() >= 16
          const isThEffectiveDate = this.data.key == 'effectiveDate' && isTH

          if (minDate || minDate === 0) {
            dateProps.minDate = setAssignDate(null, {
              value: minDate,
              unit: minDateUnit,
              t: 2,
              isOverFourPM: isThEffectiveDate && isOverFourPM
            })
          } else {
            if (isThEffectiveDate) {
              dateProps.minDate = setAssignDate(null, {
                value: isOverFourPM ? 1 : 0,
                unit: 3,
                t: 2
              })
            }
          }
        }

        if (isMaxDateRely) { // 最大事件关联其他字段
          const maxDate = this.formStore.getFieldValue(maxDateRelyPath)

          // 这里需要校验如果依赖的最大时间字段变化了并且小于当前时间，则需要把当前时间置空并重新选择
          if (!!this.currentValue && !!maxDate && (maxDate < this.currentValue)) {
            this.setCurrentValueEmpty()
          }

          dateProps.maxDate = new Date(maxDate)
        } else {
          if (maxDate || maxDate === 0) {
            dateProps.maxDate = setAssignDate(null, {
              value: maxDate,
              unit: maxDateUnit,
              t: 2
            })
          }
        }

        return dateProps
      } else {
        return {}
      }
    },
    // 是否是自动关联模式的 fixed 模式
    isAutoLinkOfFixed() {
      return this.fieldJson.autoLinking && this.fieldJson.autoLinkPeriodType == 1
    },
    // 是否是自动关联模式的 options 模式
    isAutoLinkOfOptions() {
      return this.fieldJson.autoLinking && this.fieldJson.autoLinkPeriodType == 2
    },
    // autoLinking模式 生成 period 的年限集合
    coveragePeriodOptions() {
      if (!this.fieldJson.autoLinking) { return [] }

      // 固定模式 fixed
      if (this.isAutoLinkOfFixed) {
        const { autoLinkPeriod, autoLinkPeriodUnit } = this.fieldJson
        const unit = this.unitMap[autoLinkPeriodUnit]
        const option = { label: `${autoLinkPeriod} ${autoLinkPeriod > 1 ? `${unit}s` : unit}`, labelLocal: `${autoLinkPeriod} ${unit}`, value: autoLinkPeriod }

        // 默认显示固定的年限
        this.onCoveragePeriodChange(option)

        return [option]
      } else if (this.isAutoLinkOfOptions) { // 选项模式 options
        return this.fieldJson.autoLinkPeriod ? this.fieldJson.autoLinkPeriod.split(';').map(item => {
          return { label: `${item} ${item > 1 ? 'Years' : 'Year'}`, labelLocal: `${item} ${this.unitMap[3]}`, value: item }
        }) : []
      }

      return []
    },
    // autoLinking模式下 Coverage Period 字段的显示值
    coveragePeriodShowValue() {
      if (!this.fieldJson.autoLinking) { return '' }

      if (this.isAutoLinkOfFixed) {
        return this.coveragePeriodOptions[0][this.optionsLabel]
      } else if (this.isAutoLinkOfOptions) {
        const effectiveDate = this.formStore.getFieldValue(this.autoLinkFieldPath)
        const expiredDate = this.formStore.getFieldValue(this.currentPath)

        if (effectiveDate && expiredDate) {
          // 时间单位
          const { autoLinkPeriodUnit } = this.fieldJson
          const dayTime = 24 * 60 * 60 * 1000
          const offsetTime = autoLinkPeriodUnit == 1 ? dayTime : autoLinkPeriodUnit == 2 ? dayTime * 30 : dayTime * 365
          const period = Math.round((expiredDate - effectiveDate) / offsetTime).toString()

          const currentOption = find(propEq('value', period))(this.coveragePeriodOptions) || {}

          return currentOption[this.optionsLabel]
        }

        return ''
      }

      return ''
    }
  },
  created() { },
  mounted() {
    this.initAutoLink()
    // 初始展示时间
    this.initDisplayDate()
  },
  methods: {
    setCurrentValueEmpty() {
      this.currentValue = ''
    },
    initDisplayDate() {
      const { dateStartBy, dateStartUnit = 1 } = this.fieldJson

      // 默认展示当前时间， 不需要处理
      if (!dateStartBy) { return }

      this.currentDate = getAssignDate(dateStartBy, dateStartUnit)
    },
    // 固定年限 且是 fixed 类型
    initAutoLink() {
      if (!this.fieldJson.autoLinking) { return }
      if (this.fieldJson.autoLinkPeriodType != 1) { return }

      // 添加监听关联字段变化
      this.formStore.subscribe((name) => {
        // 判断是否是监控的字段
        if (!this.formStore.isSamePath(this.autoLinkFieldPath, name)) { return }

        const { autoLinkPeriod } = this.fieldJson

        this.setExpiredDate(autoLinkPeriod)
      })
    },

    // 固定年限 且是 options 类型
    onCoveragePeriodChange(option) {
      const period = option.value

      // 计算结束时间
      this.setExpiredDate(period)
      // 关闭弹窗
      this.isShowCoveragePeriod = false
    },

    // 业务逻辑
    // 根据年限计算结束时间
    setExpiredDate(period) {
      // 获取关联字段的值
      const effectiveDate = this.formStore.getFieldValue(this.autoLinkFieldPath)

      if (!effectiveDate) { return }

      // 获取计算结束时间的相关配置
      // 默认是传统的越南配置
      const { expireDateRule = 2, expireTimeRule = '23:59:59', expireTRule = 1 } = this.fieldJson

      // 默认泰国配置
      // const { expireDateRule = 2, expireTimeRule = '16:30:00', expireTRule = 2 } = {}

      // 时间单位
      const { autoLinkPeriodUnit } = this.fieldJson
      // 这里需要转换一下
      const finalUnit = autoLinkPeriodUnit == 1 ? 3 : autoLinkPeriodUnit == 3 ? 1 : autoLinkPeriodUnit

      // 这里有一个特殊的业务逻辑处理
      // 泰国业务范围内，如果当前年是闰年的话，在 2.29 和 2.28 下单的话，起保一年的情况下
      // 止保时间都是 2.28
      // 目前已经有配置可以实现普遍情况
      // 唯一特殊处理是 在闰年的 2.29 下单需要特殊处理
      const effectiveDateObj = getTargetDate(effectiveDate)
      const { region = 'TH', zone = 7 } = Session.get('appInfo') || {}
      const isTH = region == 'TH'
      const isSpecialDay = isLeapYear(effectiveDateObj.y) && effectiveDateObj.d == 29
      const hasSpecialHardCode = isTH && isSpecialDay

      // 计算结束时间
      const expiredDate = setAssignDate(effectiveDate, {
        value: period,
        unit: finalUnit,
        year: hasSpecialHardCode ? 1 : expireDateRule,
        time: expireTimeRule,
        t: expireTRule
      })

      const timezone = getNormTimeZone(zone)
      const { y, m, d, H, M, S } = getTargetDate(expiredDate)

      const expiredDateTimestamp = getAnyTimestamp(`${y}-${m + 1}-${d} ${H}:${M}:${S}`, timezone)

      // 同步结束时间
      this.setCurrentValue(expiredDateTimestamp, ['insuranceInfo', 'expiredDate'])
    },

    // 正常选择时间点击确认
    onConfirm(value) {
      const { dateDefaultTime = '00:00:00' } = this.fieldJson
      const { zone = 7 } = Session.get('appInfo') || {}
      const timezone = getNormTimeZone(zone)
      const { y, m, d } = getTargetDate(value)
      const timestamp = getAnyTimestamp(`${y}-${m + 1}-${d} ${dateDefaultTime}`, timezone)

      this.currentValue = timestamp
      this.visibleForPopup = false
    }

    // 获取偏移时间
    // setAssignDate(date, unit, targetDate) {
    //   const { year, month, day } = getTargetDate(targetDate)
    //   const dateToInt = parseInt(date)

    //   if (unit == 1) { // year
    //     return new Date(year + dateToInt, month, day)
    //   } else if (unit == 2) { // mouth
    //     return new Date(year, month + dateToInt, day)
    //   } if (unit == 3) { // day
    //     return new Date(year, month, day + dateToInt)
    //   }
    // }
  }
}
</script>
