<template>
  <Form
    ref="FuFormMin"
    class="fu-form-min fu-form-min-label-top fu-form-mini-theme"
    :class="{
      'readonly': isFormReadOnly,
      'desktop': isDesktop
    }"
    @failed="onFailed"
  >
    <components
      :is="`FuMin${item.renderType}`"
      v-for="(item, index) in formItems"
      :key="index"
      v-model="value[item.prop]"
      :data="item"
      :path="[]"
    />
  </Form>
</template>

<script>
import { Form } from 'vant'
import FuMinObject from './materials/object.vue'
import FuMinArray from './materials/array.vue'
import FuMinGroup from './materials/group.vue'
import FormStore from './store/index'
import { isDesktop } from '../../utils/dispatch'

export default {
  name: 'FuFormMin',
  components: { Form, FuMinObject, FuMinArray, FuMinGroup },
  props: {
    value: { type: Object, default: () => {} },
    schema: { type: Object, default: () => {} },
    disabled: { type: Boolean, default: false },
    lang: { type: String, default: 'en' },
    extraForm: { type: Object, default: () => { return {} } },

    // 扩展数据
    extendDatas: { type: Object, default: () => { return {} } },

    // 是否跟踪错误字段
    // 即校验后是否滚动到错误字段的位置
    trackErrorField: { type: Boolean, default: false },

    // 测试标签
    test: { type: Boolean, default: false }
  },
  data() {
    return {
      isDesktop,
      tipPopupVisible: false,
      tipConfig: {},

      formStore: new FormStore(this.value || {}, { test: this.test })
    }
  },
  computed: {
    // 表单是否是查看详情的状态
    isFormReadOnly() {
      return this.disabled !== false
    },
    formItems() {
      return this.schema.columns || []
    }

  },
  created() {
    // 添加监听 字段值改变则更新表单数据
    this.formStore.subscribe((name) => {
      this.$emit('input', this.formStore.values)
    })

    // 关闭probeType属性(传说是因为这个启用监听滚动状态的很耗性能,关闭这个属性滑动就会流畅很多)
    document.addEventListener('touchmove', this.preventDefault, false)
  },
  destroyed() {
    document.removeEventListener('touchmove', this.preventDefault)
  },
  deactivated() {
    document.removeEventListener('touchmove', this.preventDefault)
  },
  mounted() {
    // 清除表单校验
    this.$nextTick(() => {
      setTimeout(() => {
        this.resetValidate()
      })
    })
  },
  methods: {
    preventDefault(e) {
      // Check if 'e' is a valid event object
      if (e instanceof Event) {
        e.preventDefault()
      } else {
        console.error('Invalid event object')
      }
    },
    resetValidate() {
      this.$refs.FuFormMin && this.$refs.FuFormMin.resetValidation()
    },
    validate() {
      // 这里主要是为了可以触发表单的failed方法
      if (this.trackErrorField) {
        this.$refs.FuFormMin.submit()
      }

      return this.$refs.FuFormMin.validate()
    },

    onFailed({ errors = [] }) {
      const [firstField] = errors

      // 开启错误跟踪并有错误字段的情况下
      if (firstField && this.trackErrorField) {
        this.$refs.FuFormMin.scrollToField(firstField.name)
      }
    }
  }
}
</script>

<style lang="scss">
@import './styles/index.scss';
@import './styles/theme.scss';
</style>
