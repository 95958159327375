/*
 * @Author       : Simba
 * @Date         : 2022-03-14 15:58:22
 * @LastEditors  : Simba
 * @LastEditTime : 2022-03-14 17:58:43
 * @Description  : message
 * @FilePath     : /product-web/src/components/FuFormMin/utils/validate.js
 */
export const validateStrLen = ({ minLength, maxLength, lang, name }) => {
  const message = `${name} ${getLengthMessage({ minLength, maxLength, lang })}`

  if (minLength && maxLength) {
    return {
      validator: (val) => {
        return val.toString().length >= minLength && val.toString().length <= maxLength
      }, message
    }
  }

  if (minLength) {
    return {
      validator: (val) => {
        return val.toString().length >= minLength
      }, message
    }
  }

  if (maxLength) {
    return {
      validator: (val) => {
        return val.toString().length <= maxLength
      }, message
    }
  }
}

// 校验值的大小
export const validateInt = ({ minimum, maximum, lang, name }) => {
  const message = `${name} ${getIntMessage({ minimum, maximum, lang })}`

  if (minimum && maximum) {
    return {
      validator: (val) => {
        return val * 1 >= minimum && val * 1 <= maximum
      }, message
    }
  }

  if (minimum) {
    return {
      validator: (val) => {
        return val * 1 >= minimum
      }, message
    }
  }

  if (maximum) {
    return {
      validator: (val) => {
        return val * 1 <= maximum
      }, message
    }
  }
}

// 获取长度限制的提示语
const getLengthMessage = ({ minLength, maxLength, lang }) => {
  if (minLength && maxLength) {
    if (lang == 'th') {
      return `ต้องอยู่ระหว่าง ${minLength} ถึง ${maxLength} ตัวอักษร`
    } else {
      return lang == 'en' ? `must be between ${minLength} and ${maxLength} characters` : `phải có ít nhất ${minLength} ký tự và không được vượt quá ${maxLength} ký tự`
    }
  }

  if (minLength) {
    if (lang == 'th') {
      return `ป้อนอักขระขั้นต่ำ${minLength}ตัว`
    } else {
      return lang == 'en' ? `must be at least ${minLength} characters` : `phải có ít nhất ${minLength} ký tự`
    }
  }

  if (maxLength) {
    if (lang == 'th') {
      return `กรุณากรอกไม่เกิน${maxLength}ตัวอักษร`
    } else {
      return lang == 'en' ? `must be at most ${maxLength} characters` : `không được vượt quá ${maxLength} ký tự`
    }
  }

  return ''
}

// 获取值大小限制的提示语
const getIntMessage = ({ minimum, maximum, lang }) => {
  if (minimum && maximum) {
    return lang == 'en' ? `must be >= ${minimum} and <= ${maximum}` : `cần phải >= ${minimum} và <= ${maximum}`
  }

  if (minimum) {
    return lang == 'en' ? `must be >= ${minimum}` : `cần phải >= ${minimum}`
  }

  if (maximum) {
    return lang == 'en' ? `must be <= ${maximum}` : `cần phải <= ${maximum}`
  }

  return ''
}
