<template>
  <div v-if="ifRender" class="fu-form-min-object">
    <!-- 普通展示 -->
    <div v-if="isHiddenTitle" class="fu-form-min-object-con">
      <component
        :is="`FuMin${item.renderType}`"
        v-for="(item, index) in objectItems"
        :key="index"

        :data="item"
        :path="currentPath"
        :hidden-collapse="true"
      />
    </div>

    <!-- 折叠面板 -->
    <Collapse v-else v-model="activeNames">
      <CollapseItem name="item" disabled>
        <template #title>
          <div>
            <!-- 自定义 label -->
            <FuFormLabel
              :parent="parent"
              :label="collapseItemName"
              :field-json="fieldJson"
            />

            <!-- 删除当前组的按钮 -->
            <Button
              v-if="isRepeatItem && arrayIndex != 0"
              round
              size="mini"
              type="danger"
              icon="minus"
              native-type="button"
              @click.stop="onRemoveArrayItem"
            />
          </div>
          <p v-if="isShowSameAsControl" @click.stop="() => {}">
            <Checkbox
              v-model="isCurrentItemSameAs"
              shape="square"
              @click.stop="() => {}"
              @change="onChangeSameAs"
            >{{ sameAsGroupName }}</Checkbox>
          </p>
        </template>

        <div
          v-if="isSameAsShow"
          class="fu-form-min-object-con"
          :class="{
            'disabled': isSameAsDisabled
          }"
        >
          <component
            :is="`FuMin${item.renderType}`"
            v-for="(item, index) in objectItems"
            :key="index"

            :data="item"
            :path="currentPath"
            :hidden-collapse="true"
          />
        </div>
      </CollapseItem>
    </Collapse>
  </div>
</template>

<script>
import commonMixins from '../mixins/materials'
import groupMixins from '../mixins/group'
import displayMixins from '../mixins/display'
import sameAsMixins from '../mixins/sameAs'
import { Button, Collapse, CollapseItem, Checkbox } from 'vant'

export default {
  name: 'FuMinObject',
  components: { Button, Collapse, CollapseItem, Checkbox },
  mixins: [commonMixins, groupMixins, displayMixins, sameAsMixins],
  props: {
    // 是否是数组项里面的item
    isFromArray: { type: Boolean, default: false },
    // 是否显示title
    isShowTitle: { type: Boolean, default: true },
    // 是否隐藏 collapse 一般是作用于 Object 的嵌套对象
    hiddenCollapse: { type: Boolean, default: false },
    // 是否是配置了可以 repeat 的数组项
    isRepeatItem: { type: Boolean, default: false },
    // 当前遍历的序号
    arrayIndex: { type: Number, default: 0 },
    // 在是数组项、并且显示标题的前提下  是否显示 序号 ，并控制序号是从多少开始
    itemStartIndex: { type: [Number, String, Object], default: null }
  },
  data() {
    return {
      activeNames: ['item']
    }
  },
  computed: {
    collapseItemName() {
      if (this.itemStartIndex === null) {
        return `${this.label}`
      }
      return `${this.label} ${this.itemStartIndex * 1 + this.arrayIndex}`
    },
    objectItems() {
      return this.data.columns || []
    },
    // 是否隐藏 title
    isHiddenTitle() {
      return !this.isShowTitle || this.hiddenCollapse || this.parent.isFormReadOnly
    }
  },
  methods: {
    onRemoveArrayItem() {
      this.$emit('onRemove', this.arrayIndex)
    }
  }
}
</script>

<style lang="scss" scoped>
.van-cell__title {
  .van-button {
    overflow: hidden;
  }
}
</style>
