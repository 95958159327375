<template>
  <div v-if="ifRender" class="fu-min-form-item">
    <Field
      v-model="currentValue"
      v-inputScroll
      rows="1"
      autosize
      type="textarea"
      :label="label"
      :placeholder="placeholder"
      clearable
      :name="data.name + path"
      :rules="rules"
      :readonly="parent.isFormReadOnly"
      v-bind="fieldJson"
      @focus="onIptFocus"
    >
      <!-- 自定义 label -->
      <template v-if="fieldJson.tip" slot="label">
        <FuFormLabel
          :parent="parent"
          :label="label"
          :field-json="fieldJson"
        />
      </template>

      <!-- RP-4657
    https://rd-wiki.fuseinsurtech.com/display/product/VN-Product+Factory+Improvement -->
      <template v-if="fieldJson.prefix" #button>
        <span>{{ fieldJson.prefix }}</span>
      </template>
      <template v-if="fieldJson.suffix" #extra>
        <span>{{ fieldJson.suffix }}</span>
      </template>
    </Field>

    <FuFormNote
      v-if="!parent.isFormReadOnly"
      :lang="parent.lang"
      :field-json="fieldJson"
    />
  </div>
</template>

<script>
import commonMixins from '../mixins/materials'
import displayMixins from '../mixins/display'
import inputScroll from '../directive/inputScroll/index'

export default {
  name: 'FormItemInput',
  components: {},
  directives: { inputScroll },
  mixins: [commonMixins, displayMixins],
  data() {
    return {

    }
  }
}
</script>
