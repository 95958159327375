<template>
  <div class="overlay-out-container">
    <div id="live-photo-container">
      <!-- <video
        id="video"
        autoplay
        muted
        playsinline
      /> -->
      <video
        id="video"
        width="390"
        height="422"
        muted
        :style="{'object-fit: fill;': 1 - 1 ? 'fill' : ''}"
        autoPlay
        playsInline
      />
      <canvas
        id="canvas"
        width="390"
        height="422"
      />
      <!--拍照临时存放的base64-->
      <!-- <img
        v-if="img"
        id="tempImg"
        :src="img"
        class="temp-img"
      > -->
      <div
        v-if="img"
        id="tempImg"
        :src="img"
        class="temp-img"
      ><img
        :src="img"
        width="100%"
        height="100%"
      ></div>
    </div>
    <div class="close-btn" @click="handleClose"> <Icon name="down" /> </div>
    <section
      v-landscape
      class="section"
      @touchmove.prevent
    >
      <div class="flex-right">
        <div class="right-top">
          <div class="guide-text">
            {{ currentLabel }}
          </div>
          <div class="right-top-1">
            <ul class="image-check">
              <li
                v-for="(item, k) in paramsLists"
                :key="`imageComplate${k}`"
                :class="[item.uploaded ? 'active' : 'inactive', k == startIndex ? 'curr-list' : '', aiPhotoLists[k] && aiPhotoLists[k].length && aiPhotoLists[k][0] && aiPhotoLists[k][0].status == 'done' ? 'has-photo' : '']"
                @click="jumpImage(k)"
              >
                <Icon
                  v-if="aiPhotoLists[k] && aiPhotoLists[k].length && aiPhotoLists[k][0] && aiPhotoLists[k][0].status == 'done'"
                  name="success"
                  class="done"
                />
              </li>
            </ul>
          </div>
        </div>
        <div class="case-gif">
          <div class="arrow-left" />
          <img
            :src="getImg"
            class="gif-img"
            @touchstart="handleTouchStart"
            @touchmove="handleTouchMove"
            @touchend="handleTouchEnd"
          >
          <div class="arrow-right" />
        </div>
        <div class="content-50-bottom">
          <div class="controller-container">
            <div v-if="img" class="controller-select-area">
              <!-- <Button class="th-next-btn" type="danger" plain color="#d9000b" icon="revoke" @click="handleRetake">{{ $t('livephoto.Retake') }}</Button> -->
              <!-- <Button class="th-next-btn" type="danger" color="#d9000b" :loading="loading" :disabled="loading" @click="handdleContinue">{{ $t('livephoto.Continue') }}</Button> -->
              <Button
                class="retake-btn"
                icon="delete"
                round
                type="danger"
                @click="handleRetake"
              />
              <Button
                class="continue-btn"
                icon="arrow"
                round
                type="danger"
                @click="handdleContinue"
              />
            </div>
            <div
              v-else
              class="shoot-btn"
              @click="handleShoot"
            />
          </div>
        </div>

      </div>

    </section>

    <Overlay :show="showLoading" class="overlay-loading">
      <div class="overlay-loading-nav">
        <NavBar
          :border="false"
          :title="$t(`meta['Add Inspection Info']`)"
          left-arrow
          left-text=""
          @click-left="handleCloseLoading"
        >
          <template #right>
            <slot name="right" />
          </template>
        </NavBar>
      </div>
      <div class="overlay-loading-container">
        <p class="overlay-loading-text">{{ $t('livephoto.Loading') }}<span id="loading-text">(0%)</span></p>
        <div class="overlay-loading-progress-out"><div id="overlay-loading-progress" class="overlay-loading-progress-in" /></div>
      </div>
    </Overlay>

    <Overlay
      :show="showRorate"
      class="overlay-rorate"
      @click="showRorate = false"
    >
      <div class="overlay-loading-nav">
        <NavBar
          :border="false"
          :title="$t(`meta['Add Inspection Info']`)"
          left-arrow
          left-text=""
          @click-left="handleCloseLoading"
        >
          <template #right>
            <slot name="right" />
          </template>
        </NavBar>
      </div>
      <div class="overlay-rorate-container">
        <img :src="getRegion == 'VN' ? rorateVn : rorateGif">
        <!-- <div class="rorate-text"> {{ $t('livephoto.turnOffrotation') }} </div> -->
      </div>
    </Overlay>

    <Overlay
      :show="show"
      class="overlay-rorate"
      @click="show = false, handleClose()"
    >
      <div class="overlay-rorate-container">
        <!-- <img :src="rorateGif"> -->
        <div class="rorate-text">
          {{ $t('livephoto.turnOffrotation') }}
        </div>
        <div class="back-text">
          {{ $t('next') }}
        </div>
      </div>
    </Overlay>
  </div>

</template>
<script>
import { Icon, Button, Overlay, NavBar
  // Toast
} from 'vant'
// import Mp4 from '@/assets/livePhoto/car.mp4'
import Mp4 from '@/assets/livePhoto/Front.jpg'
import { Colors, getMaxArea, calculatePadding, drawArrow, fetchWithProgress, devicemotionHandler } from '@/utils/livephoto.js'
import rorateGif from '@/assets/livePhoto/rorate.gif'
import rorateVn from '@/assets/livePhoto/rorate-vn.gif'
import { Session } from '@/utils/storage'

const modelInputShape = [1, 3, 128, 128]
const topk = 100
const iouThreshold = 0.45
const confThreshold = 0.2
const classThreshold = 0.2
// const labels = [
//   'vehicle'
// ]

export default {
  components: { Icon, Button, Overlay, NavBar },
  directives: {
  },
  props: {
    aiPhotoLists: { type: Array, default: () => [] },
    paramsLists: { type: Array, default: () => [] },
    startIndex: { type: Number, default: 0 },
    loading: { type: Boolean, default: false },
    lang: { type: String, default: 'id' },
    vehicleInspection: { type: Boolean, default: false }
  },
  data() {
    const appInfo = Session.get('appInfo')
    return {
      appInfo,
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
      toast: null,
      mediaStream: null,
      img: '',
      Mp4: Mp4,
      show: false,
      rorateGif,
      rorateVn,
      frontCameraConfig: { video: !0 },
      rearCameraConfig: { audio: false, video: { facingMode: { exact: 'environment' }}},
      showLoading: false,
      showRorate: false
    }
  },
  computed: {
    currentLabel() {
      const region = this.appInfo?.region || ''
      return this.lang == 'en' ? this.paramsLists[this.startIndex].enGuide : this.paramsLists[this.startIndex][`${region.toLowerCase()}Guide`]
    },
    getImg() {
      return this.paramsLists[this.startIndex]?.exampleSrc
    },
    getRegion() {
      const region = this.appInfo?.region || ''
      return region
    }
  },
  watch: {
    loading(newval) {
      if (newval) {
        this.img = ''
      } else {
        // this.toast.clear()
      }
    },
    startIndex(index) {
      if (this.aiPhotoLists[index] && this.aiPhotoLists[index].length && this.aiPhotoLists[index][0].fileUrl) {
        this.img = this.aiPhotoLists[index][0].fileUrl
        this.setImgSize()
      } else {
        this.img = ''
      }
    }
  },
  mounted() {
    // this.init()
    // loadScript('https://cdn.jsdelivr.net/npm/@tensorflow/tfjs@latest/dist/tf.min.js', this.initLocal)
    // console.log('------', window.tf)
    this.show = false
    this.showLoading = true
    this.resizeHandle()
    if (this.vehicleInspection) {
      this.dynamicLocal()
    } else {
      this.init()
    }
    devicemotionHandler(() => {
      // this.showRorate = false
    })
  },
  methods: {
    clearRorateLoading() {
      this.showRorate = false
      clearTimeout(window.rorateLoading)
    },
    initRorateLoading() {
      this.showRorate = true
      window.rorateLoading = setTimeout(() => {
        this.showRorate = false
      }, 5000)
    },
    isMobile() {
      if (this.$route.query.isMobile == '0') return false
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      // return false
    },
    async fixedLocal() {
      const nms = await fetchWithProgress('https://cdn.fuse.com.vn/fuseProWeb/nms-yolov5.onnx')
      const res = await fetchWithProgress('https://cdn.fuse.com.vn/fuseProWeb/yolov8n_det_train6_128.onnx', (offset, total) => {
        console.log(`${offset / total * 100}%`)
        document.getElementById('overlay-loading-progress').style.width = `${offset / total * 100}%`
        document.getElementById('loading-text').innerHTML = `(${(offset / total * 100).toFixed(2)}%)`
      })
      window.yolov5 = await window.ort.InferenceSession.create(res)
      window.nms = await window.ort.InferenceSession.create(nms)
      this.initRorateLoading()
      this.showLoading = false
      this.img = ''
      this.mediaStream = null
      const video = document.getElementById('video')
      const canvas = document.getElementById('canvas')
      const container = document.getElementById('live-photo-container')
      const clientWidth = document.documentElement.clientWidth
      const clientHeight = document.documentElement.clientHeight
      const _this = this
      video.onloadeddata = () => {
        const b = video.captureStream()
        // console.log('---', b.getVideoTracks()[0])
        this.mediaStream = b
        container.style.width = `${clientWidth}px`
        container.style.height = `${clientHeight * 0.7}px`
        const [displayWidth, displayHeight] = this.getVideoInfo()
        canvas.width = displayWidth
        canvas.height = displayHeight
      }
      video.src = Mp4
      video.play()
      function updateCanvas() {
        const {
          width: b,
          height: n
        } = canvas
        const context = canvas.getContext('2d', {
          willReadFrequently: !0
        })

        let isProcessing = !1
        context.drawImage(video, 0, 0, b, n); isProcessing || (isProcessing = !0, (async function () {
          const imageData = context.getImageData(0, 0, b, n)
          const mat = new window.cv.Mat(imageData.height, imageData.width, window.cv.CV_8UC4)
          mat.data.set(imageData.data)
          const [modelWidth, modelHeight] = modelInputShape.slice(2)
          const matC3 = new window.cv.Mat(mat.rows, mat.cols, window.cv.CV_8UC3)
          window.cv.cvtColor(mat, matC3, window.cv.COLOR_RGBA2BGR)
          const input = window.cv.blobFromImage(
            matC3,
            1 / 255.0, // normalize
            new window.cv.Size(modelWidth, modelHeight), // resize to model input size
            new window.cv.Scalar(0, 0, 0),
            true, // swapRB
            false // crop
          )
          const tensor = new window.ort.Tensor('float32', input.data32F, modelInputShape)
          const config = new window.ort.Tensor('float32', new Float32Array([topk, iouThreshold, confThreshold]))
          // const yolov5_start = performance.now()
          // console.log('-------window.yolov5', window.yolov5)
          const { output0 } = await window.yolov5.run({ images: tensor })
          // const yolov5_end = performance.now()
          // console.log(`yolov8 took ${yolov5_end - yolov5_start} milliseconds.`)

          const tf_tensor = window.tf.tensor(output0.data, output0.dims)
          var transposed_tensor = window.tf.transpose(tf_tensor, [0, 2, 1])
          const output_data = transposed_tensor.dataSync()

          const nms_input = new window.ort.Tensor('float32', output_data, transposed_tensor.shape)
          // const nms_start = performance.now()
          const { selected_idx } = await window.nms.run({ detection: nms_input, config: config })
          // const nms_end = performance.now()
          // console.log(`nms took ${nms_end - nms_start} milliseconds.`)
          // console.log(`${Math.floor(1000 / (nms_end - yolov5_start))} fps, yolov8 took ${Math.floor(yolov5_end - yolov5_start)} ms, nms took ${Math.floor(nms_end - nms_start)} ms, `)

          const boxes = []

          selected_idx.data.forEach((idx) => {
            const data = output_data.slice(idx * transposed_tensor.shape[2], (idx + 1) * transposed_tensor.shape[2]) // get rows
            const [x, y, w, h] = data.slice(0, 4)
            // const confidence = data[4]; // detection confidence
            const scores = data.slice(4) // classes probability scores
            const score = Math.max(...scores) // maximum probability scores
            const label = scores.indexOf(score) // class id of maximum probability scores
            // score *= confidence; // multiply score by conf

            const x_ratio = canvas.width / 128
            const y_ratio = canvas.height / 128
            //     const x_ratio = canvas.width/128;
            // const y_ratio = canvas.height/128;
            // filtering by score thresholds
            if (score >= classThreshold) {
              boxes.push({
                label: label,
                probability: score,
                bounding: [
                  Math.floor((x - 0.5 * w) * x_ratio), // left
                  Math.floor((y - 0.5 * h) * y_ratio), // top
                  Math.floor(w * x_ratio), // width
                  Math.floor(h * y_ratio) // height
                ]
              })
            }
          })

          _this.renderBoxes(canvas, boxes)
          mat.delete()
          matC3.delete()
          input.delete()

          // console.log(!video.paused, !video.ended, video.currentTime < video.duration)
          if (!video.paused && !video.ended) {
            window.requestAnimationFrame(updateCanvas)
          }
        }()))
      }
      updateCanvas()
    },
    async dynamicLocal() {
      const nms = await fetchWithProgress('https://cdn.fuse.com.vn/fuseProWeb/nms-yolov5.onnx')
      const res = await fetchWithProgress('https://cdn.fuse.com.vn/fuseProWeb/yolov8n_det_train6_128.onnx', (offset, total) => {
        console.log(`${offset / total * 100}%`)
        document.getElementById('overlay-loading-progress').style.width = `${offset / total * 100}%`
        document.getElementById('loading-text').innerHTML = `(${(offset / total * 100).toFixed(2)}%)`
      })
      window.yolov5 = await window.ort.InferenceSession.create(res)
      window.nms = await window.ort.InferenceSession.create(nms)
      // window.yolov5 = await window.ort.InferenceSession.create('https://cdn.fuse.com.vn/fuseProWeb/yolov8n_det_train6_128.onnx')
      // window.nms = await window.ort.InferenceSession.create('https://cdn.fuse.com.vn/fuseProWeb/nms-yolov5.onnx')
      this.initRorateLoading()
      this.showLoading = false
      this.img = ''
      this.mediaStream = null
      const video = document.getElementById('video')
      const canvas = document.getElementById('canvas')
      const container = document.getElementById('live-photo-container')
      const clientWidth = document.documentElement.clientWidth
      const clientHeight = document.documentElement.clientHeight
      const _this = this
      const camera_config = this.isMobile() ? this.rearCameraConfig : this.frontCameraConfig
      function updateCanvas() {
        const {
          width: b,
          height: n
        } = canvas
        const context = canvas.getContext('2d', {
          willReadFrequently: !0
        })

        let isProcessing = !1
        context.drawImage(video, 0, 0, b, n); isProcessing || (isProcessing = !0, (async function () {
          const imageData = context.getImageData(0, 0, b, n)
          const mat = new window.cv.Mat(imageData.height, imageData.width, window.cv.CV_8UC4)
          mat.data.set(imageData.data)
          const [modelWidth, modelHeight] = modelInputShape.slice(2)
          const matC3 = new window.cv.Mat(mat.rows, mat.cols, window.cv.CV_8UC3)
          window.cv.cvtColor(mat, matC3, window.cv.COLOR_RGBA2BGR)
          const input = window.cv.blobFromImage(
            matC3,
            1 / 255.0, // normalize
            new window.cv.Size(modelWidth, modelHeight), // resize to model input size
            new window.cv.Scalar(0, 0, 0),
            true, // swapRB
            false // crop
          )
          const tensor = new window.ort.Tensor('float32', input.data32F, modelInputShape)
          const config = new window.ort.Tensor('float32', new Float32Array([topk, iouThreshold, confThreshold]))
          // const yolov5_start = performance.now()
          // console.log('-------window.yolov5', window.yolov5)
          const { output0 } = await window.yolov5.run({ images: tensor })
          // const yolov5_end = performance.now()
          // console.log(`yolov8 took ${yolov5_end - yolov5_start} milliseconds.`)

          const tf_tensor = window.tf.tensor(output0.data, output0.dims)
          var transposed_tensor = window.tf.transpose(tf_tensor, [0, 2, 1])
          const output_data = transposed_tensor.dataSync()

          const nms_input = new window.ort.Tensor('float32', output_data, transposed_tensor.shape)
          // const nms_start = performance.now()
          const { selected_idx } = await window.nms.run({ detection: nms_input, config: config })
          // const nms_end = performance.now()
          // console.log(`nms took ${nms_end - nms_start} milliseconds.`)
          // console.log(`${Math.floor(1000 / (nms_end - yolov5_start))} fps, yolov8 took ${Math.floor(yolov5_end - yolov5_start)} ms, nms took ${Math.floor(nms_end - nms_start)} ms, `)

          const boxes = []

          selected_idx.data.forEach((idx) => {
            const data = output_data.slice(idx * transposed_tensor.shape[2], (idx + 1) * transposed_tensor.shape[2]) // get rows
            const [x, y, w, h] = data.slice(0, 4)
            // const confidence = data[4]; // detection confidence
            const scores = data.slice(4) // classes probability scores
            const score = Math.max(...scores) // maximum probability scores
            const label = scores.indexOf(score) // class id of maximum probability scores
            // score *= confidence; // multiply score by conf

            const x_ratio = canvas.width / 128
            const y_ratio = canvas.height / 128
            //     const x_ratio = canvas.width/128;
            // const y_ratio = canvas.height/128;
            // filtering by score thresholds
            if (score >= classThreshold) {
              boxes.push({
                label: label,
                probability: score,
                bounding: [
                  Math.floor((x - 0.5 * w) * x_ratio), // left
                  Math.floor((y - 0.5 * h) * y_ratio), // top
                  Math.floor(w * x_ratio), // width
                  Math.floor(h * y_ratio) // height
                ]
              })
            }
          })

          _this.renderBoxes(canvas, boxes)
          mat.delete()
          matC3.delete()
          input.delete()

          // console.log(!video.paused, !video.ended, video.currentTime < video.duration)
          if (!video.paused && !video.ended) {
            window.requestAnimationFrame(updateCanvas)
          }
        }()))
      }
      navigator.mediaDevices.getUserMedia(camera_config).then(b => {
        this.mediaStream = b
        video.srcObject = b
        video.play()
        updateCanvas()
        container.style.width = `${clientWidth}px`
        container.style.height = `${clientHeight * 0.7}px`
        const n = b.getVideoTracks()[0]
        const { width, height } = n.getSettings()
        const [displayWidth, displayHeight] = this.getVideoInfo(width, height)
        canvas.width = displayWidth
        canvas.height = displayHeight
      }).catch(b => {
        alert(b)
      })
    },
    renderBoxes(canvas, boxes) {
      // console.log('render box:', boxes)
      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height) // clean canvas

      const colors = new Colors()

      // font configs
      const font = `${Math.max(
        Math.round(Math.max(ctx.canvas.width, ctx.canvas.height) / 40),
        14
      )}px Arial`
      ctx.font = font
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillStyle = '#FF3838'
      const text1 = this.$t('livephoto.text1')
      const text2 = this.$t('livephoto.text2')

      const max = getMaxArea(boxes)
      // console.log(max)
      // boxes.forEach((box) => { // TODO:这里去掉多余的边框
      max.forEach((box) => {
        // const klass = labels[box.label]
        let color = colors.get(box.label)
        // const score = (box.probability * 100).toFixed(1)
        const [x1, y1, width, height] = box.bounding

        const canvas = document.getElementById('canvas')
        const threshold = 3 // 边距阈值
        const [paddingTop, paddingRight, paddingBottom, paddingLeft] = calculatePadding(canvas, [x1, y1, width, height], threshold)
        // console.log([paddingTop, paddingRight, paddingBottom, paddingLeft])
        // drawArrow(ctx, 10, 10, 100, 100, 30, 30)
        const arrowLength = 30 // 箭头长度
        const bufferEnd = 5 // 箭头buffer缓冲点
        const points = []
        if (paddingTop) {
          // drawArrow(ctx, canvas.width / 2, arrowLength, canvas.width / 2, 5, 10, 5)
          points.push([ctx, canvas.width / 2, arrowLength, canvas.width / 2, 5, 10, 5])
        }
        if (paddingRight) {
          // drawArrow(ctx, canvas.width - arrowLength, canvas.height / 2, canvas.width - bufferEnd, canvas.height / 2, 10, 5)
          points.push([ctx, canvas.width - arrowLength, canvas.height / 2, canvas.width - bufferEnd, canvas.height / 2, 10, 5])
        }
        if (paddingBottom) {
          // drawArrow(ctx, canvas.width / 2, canvas.height - arrowLength, canvas.width / 2, canvas.height - bufferEnd, 10, 5)
          points.push([ctx, canvas.width / 2, canvas.height - arrowLength, canvas.width / 2, canvas.height - bufferEnd, 10, 5])
        }
        if (paddingLeft) {
          // drawArrow(ctx, arrowLength, canvas.height / 2, bufferEnd, canvas.height / 2, 10, 5)
          points.push([ctx, arrowLength, canvas.height / 2, bufferEnd, canvas.height / 2, 10, 5])
        }
        if (points.length) {
          ctx.save()
          // 移动原点到 Canvas 的中心
          const x = ctx.canvas.width / 2
          const y = ctx.canvas.height / 2
          ctx.translate(x, y)

          // 旋转文字 90 度
          ctx.rotate(Math.PI / 2)
          // const lineHeight = font + 10

          // 绘制旋转后的文字
          ctx.fillText(text1, 0, -10)
          ctx.fillText(text2, 0, 10)
          // 恢复 Canvas 的状态
          ctx.restore()
          points.forEach(item => {
            drawArrow(...item)
          })
        }
        if (paddingTop || paddingRight || paddingBottom || paddingLeft) {
          color = colors.get(box.label)
        } else {
          color = '#07C160'
        }
        // console.log(color)

        // draw box.
        ctx.fillStyle = Colors.hexToRgba(color, 0.2)
        ctx.fillRect(x1, y1, width, height)
        // draw border box
        ctx.strokeStyle = color
        ctx.lineWidth = Math.max(Math.min(ctx.canvas.width, ctx.canvas.height) / 200, 2.5)
        ctx.strokeRect(x1, y1, width, height)
      })
    },
    getVideoInfo(width, height) {
      const videoElement = document.getElementById('video')
      const container = document.getElementById('live-photo-container')
      const videoWidth = width || videoElement.videoWidth
      const videoHeight = height || videoElement.videoHeight
      const containerWidth = container.clientWidth
      const containerHeight = container.clientHeight
      // 计算实际显示宽高
      const videoAspectRatio = videoWidth / videoHeight
      const containerAspectRatio = containerWidth / containerHeight
      let displayWidth, displayHeight
      if (containerAspectRatio > videoAspectRatio) {
        // 容器宽高比大于视频宽高比，视频高度与容器高度一致
        displayHeight = containerHeight
        displayWidth = containerHeight * videoAspectRatio
      } else {
        // 容器宽高比小于或等于视频宽高比，视频宽度与容器宽度一致
        displayWidth = containerWidth
        displayHeight = containerWidth / videoAspectRatio
      }
      // console.log('----------display', displayWidth, displayHeight)
      return [displayWidth, displayHeight]
    },
    init() {
      this.showLoading = false
      this.img = ''
      this.mediaStream = null
      const video = document.getElementById('video')
      const canvas = document.getElementById('canvas')
      const container = document.getElementById('live-photo-container')
      const clientWidth = document.documentElement.clientWidth
      const clientHeight = document.documentElement.clientHeight
      const camera_config = this.isMobile() ? this.rearCameraConfig : this.frontCameraConfig

      navigator.mediaDevices.getUserMedia(camera_config).then(b => {
        this.mediaStream = b
        video.srcObject = b
        video.play()
        container.style.width = `${clientWidth}px`
        container.style.height = `${clientHeight * 0.7}px`
        const n = b.getVideoTracks()[0]
        const { width, height } = n.getSettings()
        const [displayWidth, displayHeight] = this.getVideoInfo(width, height)
        canvas.width = displayWidth
        canvas.height = displayHeight
      }).catch(b => {
        alert(b)
      })
    },
    closeMediaStream() {
      if (!this.mediaStream) return
      this.mediaStream.getTracks().forEach(track => {
        track.stop()
      })
    },
    handleShoot() {
      const video = document.getElementById('video')
      const canvas = document.createElement('canvas')
      canvas.width = video.videoHeight
      canvas.height = video.videoWidth
      const ctx = canvas.getContext('2d')
      // 移动坐标系到 canvas 的中心点，然后逆时针旋转 90 度
      ctx.translate(canvas.width / 2, canvas.height / 2)
      ctx.rotate(-90 * Math.PI / 180)
      // 将图像绘制到旋转后的 canvas 上
      ctx.drawImage(video, -video.videoWidth / 2, -video.videoHeight / 2, video.videoWidth, video.videoHeight)
      const base64 = canvas.toDataURL('image/png')
      this.img = base64
      this.setImgSize()
    },
    handleRetake() {
      this.img = ''
      this.$set(this.aiPhotoLists, this.startIndex, [])
    },
    handleCloseLoading() {
      this.showLoading = false
      this.handleClose()
    },
    handleClose() {
      this.closeMediaStream()
      this.$emit('close')
    },
    handdleContinue() {
      if (!this.img) return
      if (this.aiPhotoLists?.[this.startIndex]?.[0]?.fileKey) {
        this.nextImage()
        return
      }
      this.$emit('videoPhotoUpload', this.img, this.startIndex)
      // this.closeMediaStream()
      // this.$emit('close')
    },
    handleTouchStart(event) {
      this.startX = event.touches[0].clientY // Y 作为新的 X
    },
    handleTouchMove(event) {
      this.endX = event.touches[0].clientY // Y 作为新的 X
    },
    handleTouchEnd() {
      const deltaX = this.endX - this.startX
      if (this.endX == 0) return
      if (deltaX > 30) {
        this.prevImage()
      }
      if (deltaX < -30) {
        this.nextImage()
      }

      // Reset values
      this.startX = 0
      this.startY = 0
      this.endX = 0
      this.endY = 0
    },
    jumpImage(index) {
      this.$parent.startIndex = index
    },
    nextImage() {
      if (this.$parent.startIndex + 1 > this.aiPhotoLists.length - 1) return
      this.$parent.startIndex = this.$parent.startIndex + 1
    },
    prevImage() {
      if (this.$parent.startIndex - 1 < 0) return
      this.$parent.startIndex = this.$parent.startIndex - 1
    },
    resizeHandle() {
      function isVerticalScreen() {
        const width = document.documentElement.clientWidth
        const height = document.documentElement.clientHeight
        return width < height
      }
      if (!isVerticalScreen()) {
        this.show = true
      }
      if (window.rorateResizeBind) return
      window.rorateResizeBind = true
      window.addEventListener('resize', () => {
        if (!isVerticalScreen()) {
          this.show = true
        } else {
          // this.show = false
        }
      }, false)
    },
    setImgSize() {
      this.$nextTick(() => {
        const clientWidth = document.documentElement.clientWidth
        const clientHeight = document.documentElement.clientHeight
        if (document.getElementById('tempImg')) {
          document.getElementById('tempImg').style.width = `${clientHeight * 0.7}px`
          document.getElementById('tempImg').style.height = `${clientWidth}px`
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
#video {
  height: 100%;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
}
.temp-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  transform-origin: center center;
  // top: 0;
  // left: 0;
  // transform: rotate(90deg);
  // transform-origin: center center;
}

.overlay-out-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  background-color: #E0E0E0;
}
#live-photo-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #000;
  width: 720px;
  height: 0px;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 9997;
}
.close-btn {
  font-size: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9997;
  color: red;
  border: 1px solid red;
  padding: 10px 15px;
  border-radius: 100px;
  background-color: #fff;
  transform: rotate(180deg);
  transform-origin: center center;
}
.flex-right {
  height: 100%;
  width: 30%;
  overflow: hidden;
  position: absolute;
  // left: 70%;
  right: 0%;
}
.section {
  overflow: hidden;
  z-index: 9990;
  background: #E0E0E0;
  position: absolute;
  top: 0;
  left: 0;
  .right-top-1 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .guide-text {
    font-size: 18px;
    text-align: center;
    line-height: 20px;
    color: #000;
    margin-top: 15px;
  }
  .image-check {
    display: flex;
    margin: 10px;
    align-items: center;
    .inactive {
      background: #fff;
      width: 15px;
      height: 15px;
      border-radius: 100px;
    }
    .active {
      background: #EE0A24;
    }
    .curr-list {
      // animation: zoom 0.8s infinite alternate ease-in-out;
      border: 1px solid #EE0A24;
      box-sizing: content-box;
    }
    .has-photo {
      flex: unset;
      height: unset;
      background: unset;
      color: #07C160;
      font-size: 16px;
      width: 15px;
      height: 15px;
      overflow: hidden;
      text-align: center;
    }
    .done {
      vertical-align: text-top;
      font-size: 12px;
    }
    li {
      margin-left: 10px;
      height: 8px;
      &:first-child {
        margin-left: 0;
        margin-right: 5px; // 保持一致的右边距
      }

      &:last-child {
        margin-left: 5px; // 保持一致的左边距
        margin-right: 0;
      }

      &:not(:first-child):not(:last-child) {
        margin: 0 5px;
      }
    }
  }
}
#overlay{
  position: absolute;
  width: 100%;
  height: 100%;
}
canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.content-50-bottom {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 10px;
}
.shoot-btn {
  position: relative;
  width: 60px;
  height: 60px;
  background-color: #fff;
  border: 1px solid #323233;
  border-radius: 100px;
  display: inline-block;
  &::after {
    content: '';
    padding: 5px;
    border-radius: 100px;
    background-color: #323233;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scale(0.9);
  }
}
.loading-btn {
  margin-right: 10px;
}
.retake-btn {
  // font-size: 16px;
  display: block;
  overflow: hidden;
  background: #576B95;
  border-color: #576B95;
  width: 50px;
  height: 50px;
}
.continue-btn {
  // font-size: 16px;
  display: block;
  overflow: hidden;
  width: 50px;
  height: 50px;
}
.th-next-btn {
  font-size: 14px;
  display: block;
  width: 120px;
  border-radius: 0.5rem;
  text-align: center;
  line-height: 60px;
  margin: 0;
  overflow: hidden;
}
.case-gif {
  text-align: center;
  position: absolute;
  width: 80%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  .gif-img {
    width: 300px;
    height: 170px;
    border: 1px solid #EE0A24;
    padding: 15px;
    border-radius: 15px;
    touch-action: none;
  }
}
.controller-container {
  text-align: center;
  height: 65px;
  font-size: 0;
  .controller-select-area {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    padding: 0px 10px;
  }
}
.arrow-left, .arrow-right {
  width: 0;
  height: 0;
  border-style: solid;
}
.arrow-left {
  border-width: 60px 15px 60px 0;
  border-color: transparent #EE0A24 transparent transparent;
}

.arrow-right {
  border-width: 60px 0 60px 15px;
  border-color: transparent transparent transparent #EE0A24;
}
.overlay-rorate {
  z-index: 9998;
  background-color: rgba(0, 0, 0, 1);
  .overlay-rorate-container {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100%;
    transform: translateY(-50%) scale(1.5);
  }
  .rorate-text {
    font-size: 16px;
    color: #fff;
    margin-top: 30px;
  }
  .back-text {
    text-align: center;
    text-decoration: underline;
  }
}
.overlay-loading {
  z-index: 9998;
  background-color: rgba(0, 0, 0, 1);
  .overlay-loading-container {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100%;
    transform: translateY(-50%) scale(1.5);
    .overlay-loading-text {
      color: #fff;
      margin-top: 15px;
    }
  }
  .overlay-loading-progress-out {
    width: 150px;
    padding: 2px 3px;
    margin: 0 auto;
    border: 2px solid #fff;
    border-radius: 8px;
    overflow: hidden;
    .overlay-loading-progress-in {
      width: 0%;
      height: 10px;
      background-color: #fff;
      border-radius: 8px;
      transition: width 0.2s ease-out;
    }
  }
}
.overlay-loading-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  .van-nav-bar {
    background: $defaultThemeColor;
    font-family: 'Roboto-Bold';

    ::v-deep .van-icon {
      color: #fff;
    }

    ::v-deep .van-nav-bar__title {
      color: #fff;
    }
  }
  ::v-deep .van-popover__arrow{
    right: 8px !important;
  }
}
</style>

