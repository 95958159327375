export default {
  'beneficiaryInfo': '',
  'beneficiaryInfoItem': '',
  'name': 'Tên đầy đủ',
  'firstName': 'Tên',
  'middleName': 'Tên đệm',
  'lastName': 'Họ',
  'identityType': 'Loại giấy tờ tùy thân',
  'identityNo': 'Số CMND/ CCCD',
  'identityExpires': 'Ngày hết hạn',
  'birthday': 'Ngày tháng năm sinh',
  'gender': 'Giới tính',
  'age': 'Tuổi',
  'height': 'Chiều cao',
  'weight': 'Cân nặng',
  'marital': 'Tình trạng hôn nhân',
  'professional': 'Nghề nghiệp',
  'mobilePhone': 'Số điện thoại',
  'homePhone': 'Số điện thoại nhà',
  'officePhone': 'Số điện thoại văn phòng',
  'email': 'Email',
  'homeDetailAddress': 'nhà Thông tin địa chỉ',
  'homeAddress': 'nhà Địa chỉ nhà',
  'officeDetailAddress': 'văn phòng Thông tin địa chỉ',
  'officeAddress': 'văn phòng Địa chỉ nhà',
  'benefitRatio': '',

  'expressInfo': 'Thông tin nhận thư',
  'needInvoice': 'Nhận hóa đơn bản cứng',
  'invoiceName': 'Tên hóa đơn',
  'policyType': 'Loại hợp đồng',
  'expressDetailAddress': 'thể hiện Thông tin địa chỉ',
  'expressAddress': 'thể hiện Địa chỉ nhà',
  'expressTelephone': 'Số điện thoại',
  'emailAddress': 'Email',

  'insuranceInfo': 'Thông tin bảo hiểm',
  'effectiveDate': 'Ngày hiệu lực',
  'expiredDate': 'Ngày hết hạn',

  'insuredInfo': 'Thông tin người được bảo hiểm',
  'insuredInfoItem': 'Mục thông tin được bảo hiểm',
  'relationshipWithPh': 'Mục thông tin được bảo hiểm',
  'bankCode': 'Mã ngân hàng',
  'bankCardNo': 'Số mã ngân hàng',
  'bankCardHolder': 'Chủ thẻ',
  'contactName': 'Tên người liên hệ',
  'contactTelephone': 'Số điện thoại liên hệ',
  'contactEmail': 'Email liên hệ',
  'contactIdentityType': 'Loại giấy tờ tùy thân người liên hệ',
  'contactIdentityNo': 'Số CMND/ CCCD',
  'contactIdentityExpires': 'Ngày hết hạn',
  'attachments': 'Đính kèm',
  'attachmentPath': 'Đường dẫn tệp đính kèm',

  'ownerPersonInfo': 'Thông tin chủ sở hữu',

  'policyHolderInfo': 'Thông tin người được bảo hiểm',

  'riskGroupInfo': 'Thông tin đối tượng được bảo hiểm',
  'vehicle': 'Thông tin xe',
  'newCarFlag': 'Tình trạng xe',
  'plateNo': 'Số biển kiểm soát',
  'plateArea': 'khu vực tấm',
  'brandCode': 'Hãng Xe',
  'brandModelCode': 'Hiệu xe',
  'brandSeriesCode': 'Dòng xe',
  'vehicleType': 'Loại xe',
  'vehicleUsage': 'Mục đích sử dụng',
  'vehicleColor': 'Màu xe',
  'vehicleVinNo': 'Số khung',
  'vehicleEngineNo': 'Số máy',
  'vehicleSeatCount': 'Số chỗ ngồi',
  'vehiclePayload': 'Trọng tải xe',
  'vehicleDisplacement': 'Phân khối',
  'vehicleRegisterDate': 'Ngày đăng ký',
  'vehicleManufactureYear': 'Năm sản xuất',
  'carValue': 'Giá xe',
  'usedTime': 'Thời gian sử dụng',
  'transferDate': 'Ngày chuyển khoản',
  'driverLicenseNo': 'Số bằng lái',
  'property': 'Thông tin tài sản',
  'detailAddress': 'Thông tin địa chỉ',
  'address': 'Địa chỉ nhà',
  'buildingName': 'Tên tòa nhà',
  'floor': 'Tầng',
  'roomNo': 'Số phòng',
  'price': 'Giá',
  'moveable': 'Tất cả rủi ro có thể di chuyển',
  'itemBrand': 'Thương hiệu',
  'itemModel': 'Mẫu mã',
  'itemSeries': 'Dòng sản phẩm',
  'itemDescription': 'Mô tổ sản phẩm',
  'itemSerial': 'Số seri',
  'itemCode': 'Mã sản phẩm',
  'itemPrice': 'Giá',
  'itemName': 'Tên shop',
  'itemPurchaseDate': 'Ngày mua',
  'itemDeliveryDate': 'Ngày vận chuyển'
}
