import commonMixins from './common'

export default {
  mixins: [commonMixins],
  created() {
    this.initTipNotify()
  },
  computed: {
    formStore() {
      return this.parent.formStore
    }
  },
  methods: {
    // 默认值的监听
    initTipNotify() {
      const { show, tipRely, tipRelyGather } = this.fieldJson
      // 前置判断
      if (!show || !tipRely) { return }
      // 相关配置项为空则终止
      if (!tipRelyGather || !tipRelyGather.length) { return }

      // 条件分类
      const finalTipRelyGather = this.getFinalRelyGather(tipRelyGather)

      // 初始化的是先计算一次默认值
      this.judgeRelyNote(finalTipRelyGather)

      // 添加监听关联字段变化
      this.formStore.subscribe((name) => {
        // 判断当前变动的字段是否是关联字段
        if (!this.hasRelyField(name, tipRelyGather)) { return }

        this.judgeRelyNote(finalTipRelyGather)
      })
    },

    // 计算关联 默认值
    judgeRelyNote(relyGather = []) {
      // 获取匹配条件的关联值
      const judgeRelyRes = this.judgeRely(relyGather)

      // 非空判断
      // 因为返回值有可能是 0 或 空字符串这种，所以这里要判断是否等于 undefined
      if (judgeRelyRes !== undefined) {
        this.finalRelyTip = judgeRelyRes
      }
    }
  }
}
