import { find, propEq, clone } from 'ramda'

export default {

  props: {
    // 在是数组项的前提下 是否配置了 same as
    sameAsPath: { type: Array, default: () => { return [] } },
    // 是否有已经选择item 选择了 same as
    selectSameAsItemIndex: { type: [Number, String, Object], default: null },

    sameAsShow: { type: Boolean, default: false },
    sameAsDisabled: { type: Boolean, default: false }
  },
  data() {
    return {
      isCurrentItemSameAs: false,
      sameAsSubscribe: null
    }
  },
  computed: {
    // same as 相关
    finalSameAsPath() {
      const { valueSameAs } = this.fieldJson

      // 优先判断本身的配置
      if (!!valueSameAs && !!valueSameAs.length) {
        return valueSameAs
      }

      // 判读是否有从父节点传过来的配置
      if (!!this.sameAsPath && !!this.sameAsPath.length) {
        return this.sameAsPath
      }

      return null
    },
    // 是否显示 same as
    isShowSameAsControl() {
      if (this.selectSameAsItemIndex !== null) {
        return this.selectSameAsItemIndex == this.arrayIndex
      }

      return !!this.finalSameAsPath && !!this.finalSameAsPath.length
    },
    // same as 的对象名
    sameAsGroupName() {
      if (!this.isShowSameAsControl) { return '' }

      const rootColumns = this.parent.schema
      const sameAsField = this.finalSameAsPath.reduce((prev, item) => {
        if (!prev.columns) { return {} }

        // 当配置的是数组类型的时候 只需要返回数组的信息
        if (typeof item == 'number' && prev.renderType == 'Array') {
          return prev
        } else {
          const targetField = find(propEq('key', item))(prev.columns) || {}

          return targetField
        }
      }, rootColumns)

      return this.parent.lang === 'en' ? `Same As ${sameAsField.name}` : `Giống với ${sameAsField.nameLocal}`
    },
    // 选择了 same as 后是否展示
    isSameAsShow() {
      if (this.selectSameAsItemIndex !== this.arrayIndex) { return true }

      return this.sameAsShow
    },
    // 选择了 same as 后是否可编辑
    isSameAsDisabled() {
      if (this.selectSameAsItemIndex !== this.arrayIndex) { return false }

      return this.sameAsDisabled
    },
    // 获取 当前group下的 relationshipWithPh 字段
    relationshipWithPhField() {
      return find(propEq('key', 'relationshipWithPh'))(this.data.columns)
    }
  },
  methods: {
    // same as checkbox 变化时的回调
    onChangeSameAs(value) {
      if (value) {
        this.initSameAsNotify()
      } else {
        // IDP-7465 在取消选中的时候需要清空当前组的信息
        this.handleManualCopy(true)
        this.destroySameAsNotify()
      }

      // same as 为 true 的时候，  relationshipWithPh = myself
      const relationshipWithPh = value ? 1 : ''
      const nullValue = { label: '', labelLocal: '' }
      const mySelfOption = value && this.relationshipWithPhField ? find(propEq('value', relationshipWithPh))(this.relationshipWithPhField.fieldJson.options || []) || nullValue : nullValue
      this.formStore.setFieldValue([...this.currentPath, 'relationshipWithPh'], relationshipWithPh)
      this.formStore.setFieldValue([...this.currentPath, 'relationshipWithPhLabel'], mySelfOption.label)
      this.formStore.setFieldValue([...this.currentPath, 'relationshipWithPhLabelLocal'], mySelfOption.labelLocal)

      // 如果不显示的话则默认收起
      if (!this.isSameAsShow) {
        this.activeNames = []
      }

      this.$emit('onSameAsChange', { value, index: this.arrayIndex })
    },

    // 开始监听
    initSameAsNotify() {
      // 先手动复制一次内容
      this.handleManualCopy()

      // 添加监听关联字段变化
      this.sameAsSubscribe = this.formStore.subscribe((name) => {
        // 判断当前变化值的字段所在组 是否是配置了 same as 的组
        if (!this.finalSameAsPath.every(key => { return name.includes(key) })) { return }

        const targetValue = this.formStore.getFieldValue(name)
        const targetPath = [
          ...this.currentPath,
          name[name.length - 1]
        ]

        this.formStore.setFieldValue(targetPath, targetValue)
      })
    },
    // 取消监听
    destroySameAsNotify() {
      this.sameAsSubscribe && this.sameAsSubscribe()
      this.sameAsSubscribe = null
    },

    // 手动复制内容
    // isEmpty == true 则表示清空当前对象
    handleManualCopy(isEmpty) {
      const targetValue = clone(this.formStore.getFieldValue(this.finalSameAsPath))

      if (Array.isArray(targetValue) && typeof this.currentPath[this.currentPath.length - 1] == 'number') {
        const copyPath = clone(this.currentPath)
        const prevPath = copyPath.splice(0, copyPath.length - 1)
        const finalTargetValue = isEmpty ? [] : targetValue

        this.formStore.setFieldValue(prevPath, finalTargetValue)
      } else {
        const finalTargetValue = isEmpty ? {} : targetValue

        this.formStore.setFieldValue(this.currentPath, finalTargetValue)
      }
    }
  }
}
