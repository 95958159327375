import { path, clone, assocPath, type, equals, pipe, __, when, ifElse, F, forEach } from 'ramda'
import { isNameType, strToArray, isObject, pickPaths } from './utils'

// 常量模块
// 此标识符意味着通知所有组件更新
const ALL = Symbol('*')
// 此标识符用来标识formStore
const FORM_SIGN = Symbol('formStoreSign')
// 导出内部方法的标识符
const INNER_HOOKS_SIGN = Symbol('innerHooks')

export default class FormStore {
  constructor(initialValue, config = {}) {
    this.initialValue = initialValue
    this.values = initialValue ? clone(initialValue) : {}
    this.listeners = []
    this.config = config
  }

  // 获取表单值
  getFieldsValue = () => {
    return clone(this.values)
  }

  // 这里的name不一定是字符串，也有可能是字符串数组,或者数组下标（string | string | number[]）
  // 例如：name = ['a', 'b']意思是获取form表单值（value）对象的value[a][b]属性值
  getFieldValue = (name) => {
    return ifElse(
      isNameType,
      pipe(strToArray, path(__, this.values)),
      F
    )(name, true)
  }

  // 设置form表单 单个值的方法
  setFieldValue = (name, value) => {
    pipe(
      strToArray,
      (newName) => {
        this.values = assocPath(newName, value, this.values)

        if (!this.config.test) {
          this.notify(name)
        }
      }
    )(name)
  }

  // 设置form表单 多个值的方法
  setFieldsValue = (value) => {
    return when(
      isObject,
      // pickPath方法能把对象解析为路径
      // pickPaths({a: 2, c: 3 })
      // => [[{path: 'a', value: 2 }], [{ path: 'c', value: 3 }]]
      pipe(pickPaths, forEach((item) => {
        this.values = assocPath(item.path, item.value, this.values)
      }), () => this.notify(ALL))
    )(value)
  }

  // 通知的方法,通知单个或者所有组件更新表单值
  notify = (name) => {
    for (const listener of this.listeners) listener(name)
  }

  // 订阅事件的方法，返回清除事件的函数，在组件卸载的时候需要清除这个组件订阅的事件
  subscribe = (listener) => {
    if (this.config.test) { return }

    this.listeners.push(listener)
    return () => {
      const index = this.listeners.indexOf(listener)
      if (index > -1) this.listeners.splice(index, 1)
    }
  }

  // 判断两个路径是否相等，如下
  // equals([1, 2, 3], [1, 2, 3]); //=> true
  isSamePath = (path1, path2) => {
    if (type(path1) !== 'Array' || type(path2) !== 'Array') {
      throw new Error('isSamePath函数的参数均需数组')
    }
    return equals(path1, path2)
  }

  // 暴露formStore的内部方法给外面，不让其直接访问FormStore
  getFormExport = (schema) => {
    return {
      signType: FORM_SIGN,
      getFieldValue: this.getFieldValue,
      setFieldValue: this.setFieldValue,
      setFieldsValue: this.setFieldsValue,
      isSamePath: this.isSamePath,
      getFieldsValue: this.getFieldsValue,
      getInnerHooks: this.getInnerHooks(schema)
    }
  }

  // 获取内部方法，只在内部组件使用
  getInnerHooks = schema => sign => {
    if (sign === INNER_HOOKS_SIGN) {
      return {
        getFieldsValue: this.getFieldsValue,
        getFieldValue: this.getFieldValue,
        setFieldValue: this.setFieldValue,
        setFieldsValue: this.setFieldsValue,
        isSamePath: this.isSamePath,
        subscribe: this.subscribe,
        notify: this.notify,
        schema
      }
    }
    console.warn('外部禁止使用getInnerHooks方法')
    return null
  }
}

