// import { propEq, find } from 'ramda'

export default {
  data() {
    return {
      dynamicRules: {},
      dynamicConfig: {}
    }
  },
  created() {
    this.initValueLimitNotify()
  },
  methods: {
    // options 的值关联监听
    initValueLimitNotify() {
      if (!this.fieldJson.limit) { return }
      if (!this.fieldJson.limitConfig || this.fieldJson.limitConfig.limitType != '2') { return }
      if (!this.fieldJson.limitConfig.path || !this.fieldJson.limitConfig.path.length) { return }

      const { path, minType, minValue, maxType, maxValue } = this.fieldJson.limitConfig

      // 添加监听关联字段变化
      this.formStore.subscribe((name) => {
        // 判断当前变动的字段是否是关联字段
        if (!this.formStore.isSamePath(name, path)) { return }

        const associatedValue = this.formStore.getFieldValue(path)

        // 关联字段的值是空的时候则终止
        if (!associatedValue) {
          this.dynamicRules = {}
          return
        }

        // type: 1: fixed 2: 百分比
        const min = minType == 2 ? Math.floor(associatedValue * (minValue / 100), 2) : associatedValue * 1 + minValue * 1
        const max = maxType == 2 ? Math.floor(associatedValue * (maxValue / 100), 2) : associatedValue * 1 + maxValue * 1

        // 判断如果最小值大于最大值
        if (min > max) {
          this.dynamicRules = {}
          return
        }

        this.dynamicConfig = {
          min,
          max
        }

        this.dynamicRules = { validator: (val) => {
          return val >= min && val <= max
        }, message: this.parent.lang == 'en' ? `Must be greater than or equal to ${min} and less than or equal to ${max}` : `phải lớn hơn hoặc bằng ${min} và nhỏ hơn hoặc bằng ${max}` }
      })
    }
  }
}
