<template>
  <p>
    <span style="margin-right: 3px;">{{ label }}</span>

    <span @click.stop="onClickTip">
      <!-- popover 模式 -->
      <Popover
        v-if="isPopover"
        v-model="showPopover"
        :close-on-click-outside="true"
        placement="bottom-start"
        theme="dark"
        trigger="click"
      >
        <div
          class="fu-mini-form-tip-popover"
          :style="noteStyle"
          v-html="getDialogContent()"
        />

        <template #reference>
          <Icon
            v-if="labelIcon"
            :name="labelIcon"
            color="#4B4B4B"
          />
          <span v-if="fieldJson.tipTitle">{{ finalTipLabel }}</span>
        </template>
      </Popover>

      <!-- 其他模式 -->
      <template v-else>
        <Icon
          v-if="labelIcon"
          :name="labelIcon"
          color="#4B4B4B"
        />

        <span v-if="fieldJson.tipTitle">{{ finalTipLabel }}</span>
      </template>
    </span>
  </p>
</template>

<script>
import { Icon, Dialog, Popover } from 'vant'
import TipRelyMixins from '../mixins/tipRely'

export default {
  name: 'FuFormMinItemNote',
  components: { Icon, Popover },
  mixins: [TipRelyMixins],
  props: {
    parent: { type: Object, default: () => {} },
    label: { type: String, default: '' },
    fieldJson: { type: Object, default: () => {} }
  },
  data() {
    return {
      finalRelyTip: null,
      showPopover: false
    }
  },
  computed: {
    show() {
      return this.fieldJson && this.fieldJson.note
    },
    noteStyle() {
      if (!this.fieldJson.noteStyle) { return {} }

      const { fontSize, lineHeight } = this.fieldJson.noteStyle
      const assignStyle = {}
      if (fontSize) {
        assignStyle.fontSize = `${fontSize}px`
      }
      if (lineHeight) {
        assignStyle.lineHeight = `${lineHeight}px`
      }

      return {
        ...this.fieldJson.noteStyle,
        ...assignStyle
      }
    },

    finalTipConfig() {
      return this.finalRelyTip || this.fieldJson
    },

    isPopover() {
      if (this.fieldJson.tipShowType == 'popover') { return true }

      if (this.fieldJson.tipShowType == 'auto' && !this.finalTipConfig.tipContentImg) { return true }

      return false
    },

    labelIcon() {
      const { tipLabelIcon } = this.fieldJson

      return tipLabelIcon ? `${tipLabelIcon}-o` : ''
    },

    finalTipLabel() {
      return this.parent.lang == 'en' ? this.finalTipConfig.tipLabel : this.finalTipConfig.tipLabelLocal || this.finalTipConfig.tipLabel
    },
    finalTipTitle() {
      return this.parent.lang == 'en' ? this.finalTipConfig.tipTitle : this.finalTipConfig.tipTitleLocal || this.finalTipConfig.tipTitle
    },
    finalTipContent() {
      return this.parent.lang == 'en' ? this.finalTipConfig.tipContent : this.finalTipConfig.tipContentLocal || this.finalTipConfig.tipContent
    },
    tipContentImgUrl() {
      if (!this.finalTipConfig.tipContentImg) { return '' }

      return this.finalTipConfig.tipContentImg
    }
  },
  methods: {
    onClickTip() {
      if (this.isPopover) { return }

      const { tipContentImg, tipContent } = this.finalTipConfig
      if (!tipContentImg && !tipContent) { return }

      this.tipPopupVisible = true

      Dialog.confirm({
        title: this.finalTipTitle,
        messageAlign: 'left',
        theme: 'round-button',
        showConfirmButton: false,
        // confirmButtonText: this.$t('confirm'),

        showCancelButton: true,
        cancelButtonText: this.$t('close'),
        cancelButtonColor: '#C82A21',

        closeOnClickOverlay: true,
        overlayClass: 'fu-min-form-tip-overlay',
        message: this.getDialogContent()
      })
        .then(() => {
          // on confirm
        })
        .catch(() => {
          // on cancel
        })
    },
    getDialogContent() {
      const { tipContentImg } = this.finalTipConfig
      const contents = []

      if (this.finalTipContent) {
        contents.push(`<p>${this.finalTipContent}</p>`)
      }

      if (tipContentImg) {
        contents.push(`<image
          width="100%"
          src="${this.tipContentImgUrl}"
        ></image>`)
      }
      return contents.join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
.fu-mini-form-tip-popover {
  padding: 10px;
  width: 240px;
}
</style>
