// 用于解决安卓弹出小键盘导致输入框被挡住的问题
function InputScroll() {
  // 获取元素在可视窗口的位置信息
  const { top, height } = this?.getBoundingClientRect() || {}
  // 获取元素最近一级position父级
  const parentDom = this.offsetParent
  const parentScroll = parentDom.scrollTop

  setTimeout(() => {
    // 获取当前可视窗口高度
    const innerH = window.innerHeight
    // 计算元素顶部和当前高度的差值
    const compareH = top - innerH

    // 这里的数值就是距离键盘的高度
    if (compareH > 0) {
      parentDom.scrollTop = compareH + parentScroll + height + 80
    } else if (Math.abs(compareH) < (height + 50)) {
      // 数值50, 表示第二种情况,被挡住的多少
      parentDom.scrollTop = parentScroll + height + 80
    }
  }, 600)
}

export default {
  bind(el, binding, vnode) {
    el.addEventListener('click', InputScroll, false)
  },
  unbind(el) {
    el.removeEventListener('click', InputScroll)
  }
}

