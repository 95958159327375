import commonMixins from './common'

export default {
  mixins: [commonMixins],
  created() {
    this.initNoteNotify()
  },
  computed: {
    parent() {
      let parent = this.$parent

      if (!parent) {
        return parent
      }
      let parentName = parent.$options.name

      while (parentName !== 'FuFormMin') {
        parent = parent.$parent
        parentName = parent.$options.name
      }
      return parent
    },
    formStore() {
      return this.parent.formStore
    }
  },
  methods: {
    // 默认值的监听
    initNoteNotify() {
      const { show, noteRely, noteRelyGather } = this.fieldJson
      // 前置判断
      if (!show || !noteRely) { return }
      // 相关配置项为空则终止
      if (!noteRelyGather || !noteRelyGather.length) { return }

      // 条件分类
      const finalNoteRelyGather = this.getFinalRelyGather(noteRelyGather)

      // 初始化的是先计算一次默认值
      this.judgeRelyNote(finalNoteRelyGather)

      // 添加监听关联字段变化
      this.formStore.subscribe((name) => {
        // 判断当前变动的字段是否是关联字段
        if (!this.hasRelyField(name, noteRelyGather)) { return }

        this.judgeRelyNote(finalNoteRelyGather)
      })
    },

    // 计算关联 默认值
    judgeRelyNote(relyGather = []) {
      // 获取匹配条件的关联值
      const judgeRelyRes = this.judgeRely(relyGather)

      // 非空判断
      // 因为返回值有可能是 0 或 空字符串这种，所以这里要判断是否等于 undefined
      if (judgeRelyRes !== undefined) {
        this.finalNoteRelyText = this.lang == 'en' ? judgeRelyRes.en : judgeRelyRes.local
      }
    }
  }
}
