import { isArray } from '../store/utils'

export default {
  methods: {
    // 根据不同的条件重新排列关联关系
    getFinalRelyGather(relyGather = []) {
      const resGather = []

      relyGather.forEach(item => {
        const newIndex = resGather.length - 1
        if (item.type == 'if') {
          resGather.push([item])
        } else {
          resGather[newIndex].push(item)
        }
      })

      return resGather
    },

    // 关联计算
    judgeRely(relyGather) {
      for (let i = 0; i < relyGather.length; i++) {
        const relyItem = relyGather[i]

        let relyRes = true

        for (let j = 0; j < relyItem.length; j++) {
          const item = relyItem[j]

          if (item.type == 'if') {
            relyRes = this.judgeByConditions(item.list)
          } else if (item.type == 'or') {
            // 如果是 或 关系，则判断之前的结果是不是 true
            // 如果是 true 就直接返回 true
            // 如果是 false 就继续判断
            if (relyRes) {
              break
            } else {
              relyRes = this.judgeByConditions(item.list)
            }
          } else {
            // 如果是 且 的关系，就判断之前的结果
            // 如果是 true 就继续往下判断
            // 如果是 false 就直接终止
            if (relyRes) {
              relyRes = this.judgeByConditions(item.list)
            } else {
              break
            }
          }
        }

        // 如果当前条件组关联结果为 true 就取当前关联组对应的value并终止循环
        if (relyRes) {
          return relyItem[relyItem.length - 1].targetValue
        }
      }
    },

    // 判断一组条件
    judgeByConditions(conditions) {
      return conditions.every(item => {
        return this.judgeByConditionItem(item)
      })
    },
    // 判断单个条件
    judgeByConditionItem(item) {
      if (!item.path || !item.type || !item.value || !item.value.length) {
        return true
      }

      const relyFieldValue = this.formStore.getFieldValue(item.path)

      // 根据配置的逻辑来判断
      switch (item.type) {
        // >
        case 1:
          return relyFieldValue * 1 > item.value * 1
        // >=
        case 2:
          return relyFieldValue * 1 >= item.value * 1
        // <
        case 3:
          return relyFieldValue * 1 < item.value * 1
        // <=
        case 4:
          return relyFieldValue * 1 <= item.value * 1
        // =
        case 5:
          return relyFieldValue == item.value
        // ⊃ 包含
        case 6:
          if (isArray(relyFieldValue)) {
            return relyFieldValue.some(val => {
              return item.value.includes(val)
            })
          }

          return item.value.includes(relyFieldValue)
      }
    },
    // 判断配置的关联数据是否有当前变动字段
    hasRelyField(path, relyGather) {
      if (!Array.isArray(relyGather)) { return false }

      return relyGather.some(items => {
        return items.list && items.list.some(item => {
          return this.formStore.isSamePath(path, item.path)
        })
      })
    },

    displayRelyGatherValued(displayRelyGather) {
      return displayRelyGather.every(items => {
        return items.list && items.list.every(item => {
          return !item.path.length && !item.type && !item.value && !item.value.length
        })
      })
    }
  }
}
