<template>
  <div v-if="ifRender" class="fu-min-form-item">

    <!-- 展示字段 -->
    <Field
      v-model="numericDisplayValue"
      v-inputScroll
      :label="label"
      :placeholder="placeholder"
      :type="fieldJson.valueType || 'number'"
      clearable
      :name="data.name + path"
      :readonly="parent.isFormReadOnly"
      :rules="fieldJson.filter ? [] :rules"
      :class="{
        'fu-min-form-numeric-normal': fieldJson.filter
      }"
      v-bind="fieldJson"
      @blur="onBlur"
      @focus="onIptFocus"
    >
      <!-- 自定义 label -->
      <template v-if="fieldJson.tip" slot="label">
        <FuFormLabel
          :parent="parent"
          :label="label"
          :field-json="fieldJson"
        />
      </template>

      <!-- RP-4657
    https://rd-wiki.fuseinsurtech.com/display/product/VN-Product+Factory+Improvement -->
      <template v-if="fieldJson.prefix" #button>
        <span>{{ fieldJson.prefix }}</span>
      </template>
      <template v-if="fieldJson.suffix" #extra>
        <span>{{ fieldJson.suffix }}</span>
      </template>
    </Field>

    <!-- 添加一个隐藏的字段用来做校验 -->
    <Field
      v-if="ifRender && fieldJson.filter"
      v-model="currentValue"
      class="fu-min-form-numeric-disabled"
      :placeholder="placeholder"
      type="number"
      clearable
      :name="`${data.name}-validate`"
      :readonly="parent.isFormReadOnly"
      :rules="rules"

      v-bind="fieldJson"
    />

    <FuFormNote
      v-if="!parent.isFormReadOnly"
      :lang="parent.lang"
      :field-json="fieldJson"
    />
  </div>
</template>

<script>
import commonMixins from '../mixins/materials'
import displayMixins from '../mixins/display'
import numericMixins from '../mixins/numeric'
import inputScroll from '../directive/inputScroll/index'

export default {
  name: 'FormItemInput',
  components: {},
  directives: { inputScroll },
  mixins: [commonMixins, displayMixins, numericMixins],
  data() {
    return {

    }
  },
  methods: {
    onBlur() {
      if (!this.fieldJson.filter) { return }

      // 失焦后出发校验
      this.parent.$refs.FuFormMin.validate(`${this.data.name}-validate`)
    }
  }
}
</script>
