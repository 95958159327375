export default {
  data() {
    return {
      itemsNumForTravel: 0
    }
  },
  created() {
    this.travelHookInit()
  },
  computed: {
    isTravelHooksHidden() {
      return this.itemsNumForTravel === null
    }
  },
  methods: {
    travelHookInit() {
      const { extraConfig = {}} = this.fieldJson
      if (!extraConfig.dynamicLimitForTravel) { return }

      const quotationDatas = this.getQuotationDatas()
      const { riskGroupInfo = {}} = quotationDatas
      const { travel = {}} = riskGroupInfo
      const { adult = 0, children = 0 } = travel

      const insuredNum = (adult * 1 + children * 1)

      // 如果选择了个人则终止
      // 或者计算的人数是0的时候也终止
      // TODO: 需要新增功能 这个暂时禁止
      if (insuredNum === 0) {
        // this.itemsNumForTravel = null
        this.itemsNumForTravel = 0
        return
      }

      this.itemsNumForTravel = insuredNum
    },

    getQuotationDatas() {
      return this.parent.extraForm || {}
    }
  }
}
