export const attachmentType = (lang) => {
  return attachmentTypeMap[lang] || attachmentTypeMap.en
}

// 文案集合
const attachmentTypeMap = {
  // 英文
  en: {
    'rightFront': 'Right-front view picture',
    'leftFront': 'Left-front view picture',
    'rightRear': 'Right-rear view picture',
    'leftRear': 'Left-rear view picture',
    'front': 'Front view picture',
    'back': 'Back view picture',
    'left': 'Left side of the car picture',
    'right': 'Right side of the car picture',
    'registrationStamp': 'Picture of registration stamp on the front glass of the vehicle',
    'carInvoice': 'Car Invoice',
    'identityCard': 'Identity Card',
    'carRegistrationBook': 'Car’s Registration Book',
    'other': 'Other'
  },
  vi: {
    'rightFront': 'Hình ảnh nhìn từ phía trước bên phải',
    'leftFront': 'Hình ảnh mặt trước bên trái',
    'rightRear': 'Ảnh chụp từ phía sau bên phải',
    'leftRear': 'Ảnh chụp từ phía sau bên trái',
    'front': 'Hình ảnh mặt trước',
    'back': 'Xem lại hình ảnh',
    'left': 'Bên trái của hình ảnh ô tô',
    'right': 'Bên phải của hình ảnh ô tô',
    'registrationStamp': 'Hình ảnh tem đăng kiểm trên kính trước của xe',
    'carInvoice': 'Hóa đơn ô tô',
    'identityCard': 'Chứng minh nhân dân',
    'carRegistrationBook': 'Sổ đăng ký ô tô',
    'other': 'Khác'
  },
  th: {
    'rightFront': 'ภาพด้านหน้าขวา',
    'leftFront': 'ภาพมุมมองด้านหน้าซ้าย',
    'rightRear': 'ภาพด้านหลังขวา',
    'leftRear': 'ภาพมุมมองด้านหลังซ้าย',
    'front': 'ภาพด้านหน้า',
    'back': 'ดูภาพย้อนหลัง',
    'left': 'รูปรถด้านซ้าย',
    'right': 'รูปรถฝั่งขวา',
    'registrationStamp': 'ภาพตราประทับทะเบียนที่กระจกหน้ารถ',
    'carInvoice': 'ใบกำกับสินค้า',
    'identityCard': 'บัตรประชาชน',
    'carRegistrationBook': 'เล่มทะเบียนรถ',
    'businessLicense': 'ใบอนุญาตจดทะเบียนบริษัท',
    'other': 'อื่นๆ'
  }
}

// logo map
export const logoMap = {
  // 车辆品牌
  brandCode: {
    'ACURA': 'https://static.fuse.com.vn/assets/carLogo/Acura.png',
    'ALFA': 'https://static.fuse.com.vn/assets/carLogo/Alfa-Romeo.png',
    'ASTON-MARTIN': 'https://static.fuse.com.vn/assets/carLogo/Aston-Martin.png',
    'AUDI': 'https://static.fuse.com.vn/assets/carLogo/Audi.png',
    'BMW': 'https://static.fuse.com.vn/assets/carLogo/BMW.png',
    'BAIC': 'https://static.fuse.com.vn/assets/carLogo/Baic.png',
    'BENTLEY': 'https://static.fuse.com.vn/assets/carLogo/Bentley.png',
    'BENZ': 'https://static.fuse.com.vn/assets/carLogo/Mercedes-benz.png',
    'MERCEDES-BENZ': 'https://static.fuse.com.vn/assets/carLogo/Mercedes-benz.png',
    'CADILLAC': 'https://static.fuse.com.vn/assets/carLogo/Cadillac.png',
    'CHEVROLET': 'https://static.fuse.com.vn/assets/carLogo/Chevrolet.png',
    'CHERY': 'https://static.fuse.com.vn/assets/carLogo/Chery.png',
    'CHRYSLER': 'https://static.fuse.com.vn/assets/carLogo/Chrysler.png',
    'CITROEN': 'https://static.fuse.com.vn/assets/carLogo/Citroen.png',
    'DAEWOO': 'https://static.fuse.com.vn/assets/carLogo/Daewoo.png',
    'DAIHATSU': 'https://static.fuse.com.vn/assets/carLogo/Daihatsu.png',
    'DONGBEN': 'https://static.fuse.com.vn/assets/carLogo/Dongben.png',
    'DONGFENG': 'https://static.fuse.com.vn/assets/carLogo/Dongfeng.png',
    'FIAT': 'https://static.fuse.com.vn/assets/carLogo/Fiat.png',
    'FERRARI': 'https://static.fuse.com.vn/assets/carLogo/Ferrari.png',
    'FAW': 'https://static.fuse.com.vn/assets/carLogo/Faw.png',
    'FUSO': 'https://static.fuse.com.vn/assets/carLogo/Fuso.png',
    'FORD': 'https://static.fuse.com.vn/assets/carLogo/Ford.png',
    'HINO': 'https://static.fuse.com.vn/assets/carLogo/Hino.png',
    'HOWO': 'https://static.fuse.com.vn/assets/carLogo/Howo.png',
    'HONDA': 'https://static.fuse.com.vn/assets/carLogo/Honda.png',
    'HYUNDAI': 'https://static.fuse.com.vn/assets/carLogo/Hyundai.png',
    'INFINITI': 'https://static.fuse.com.vn/assets/carLogo/Infiniti.png',
    'ISUZU': 'https://static.fuse.com.vn/assets/carLogo/Isuzu.png',
    'JAC': 'https://static.fuse.com.vn/assets/carLogo/Jac.png',
    'JRD': 'https://static.fuse.com.vn/assets/carLogo/Jad.png',
    'JAGUAR': 'https://static.fuse.com.vn/assets/carLogo/Jaguar.png',
    'JEEP': 'https://static.fuse.com.vn/assets/carLogo/Jeep.png',
    'KIA': 'https://static.fuse.com.vn/assets/carLogo/Kia.png',
    'LEXUS': 'https://static.fuse.com.vn/assets/carLogo/Lexus.png',
    'LIFAN': 'https://static.fuse.com.vn/assets/carLogo/LIFAN.png',
    'LINCOLN': 'https://static.fuse.com.vn/assets/carLogo/LINCOLN.png',
    'LUXGEN': 'https://static.fuse.com.vn/assets/carLogo/LUXGEN.png',
    'LAMBORGHINI': 'https://static.fuse.com.vn/assets/carLogo/Lamborghini.png',
    'LAND-ROVER': 'https://static.fuse.com.vn/assets/carLogo/Land-Rover.png',
    'MASERATI': 'https://static.fuse.com.vn/assets/carLogo/MASERATI.png',
    'MEKONG': 'https://static.fuse.com.vn/assets/carLogo/MEKONG.png',
    'MAZDA': 'https://static.fuse.com.vn/assets/carLogo/Mazda.png',
    'MG': 'https://static.fuse.com.vn/assets/carLogo/MG.png',
    'MINI': 'https://static.fuse.com.vn/assets/carLogo/MINI.png',
    'MITSUBISHI': 'https://static.fuse.com.vn/assets/carLogo/Mitsubishi.png',
    'NISSAN': 'https://static.fuse.com.vn/assets/carLogo/Nissan.png',
    'OPEL': 'https://static.fuse.com.vn/assets/carLogo/OPEL.png',
    'PEUGEOT': 'https://static.fuse.com.vn/assets/carLogo/Peugeot.png',
    'PORSCHE': 'https://static.fuse.com.vn/assets/carLogo/Porsche.png',
    'RAM': 'https://static.fuse.com.vn/assets/carLogo/RAM.png',
    'ROLLS-ROYCE': 'https://static.fuse.com.vn/assets/carLogo/ROLLS ROYCE.png',
    'RENAULT': 'https://static.fuse.com.vn/assets/carLogo/Renault.png',
    'SAMSUNG': 'https://static.fuse.com.vn/assets/carLogo/SAMSUNG.png',
    'SCION': 'https://static.fuse.com.vn/assets/carLogo/SCION.png',
    'SHUGUANG': 'https://static.fuse.com.vn/assets/carLogo/SHUGUANG.png',
    'SAAB': 'https://static.fuse.com.vn/assets/carLogo/Saab.png',
    'SMART': 'https://static.fuse.com.vn/assets/carLogo/SMART.png',
    'SKODA': 'https://static.fuse.com.vn/assets/carLogo/Skoda.png',
    'SUBARU': 'https://static.fuse.com.vn/assets/carLogo/Subaru.png',
    'SUNLONG': 'https://static.fuse.com.vn/assets/carLogo/Sunlong.png',
    'SSANGYONG': 'https://static.fuse.com.vn/assets/carLogo/SSANGYONG.png',
    'SUZUKI': 'https://static.fuse.com.vn/assets/carLogo/Suzuki.png',
    'SYM': 'https://static.fuse.com.vn/assets/carLogo/SYM.png',
    'TATA': 'https://static.fuse.com.vn/assets/carLogo/Tata.png',
    'TERACO': 'https://static.fuse.com.vn/assets/carLogo/TERACO.png',
    'THACO': 'https://static.fuse.com.vn/assets/carLogo/THACO.png',
    'TESLA': 'https://static.fuse.com.vn/assets/carLogo/Tesla.png',
    'TOYOTA': 'https://static.fuse.com.vn/assets/carLogo/Toyota.png',
    'VINAXUKI': 'https://static.fuse.com.vn/assets/carLogo/VINAXUKI.png',
    'VINFAST': 'https://static.fuse.com.vn/assets/carLogo/VINFAST.png',
    'VOLKSWAGEN': 'https://static.fuse.com.vn/assets/carLogo/Volkswagen.png',
    'VOLVO': 'https://static.fuse.com.vn/assets/carLogo/Volvo.png',
    'WULING': 'https://static.fuse.com.vn/assets/carLogo/Wuling.png',
    'YUTONG': 'https://static.fuse.com.vn/assets/carLogo/Yutong.png',
    'ZOTYE': 'https://static.fuse.com.vn/assets/carLogo/ZOTYE.png',
    'default': 'https://static.fuse.com.vn/assets/carLogo/Default.png'
  }
}
