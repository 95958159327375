<template>
  <div v-if="ifRender" class="fu-min-form-item">
    <Field
      readonly
      clickable
      :name="data.name + path"
      :rules="rules"
      :value="fieldShowValue"
      :label="label"
      :placeholder="placeholder"
      right-icon="arrow-down"
      v-bind="fieldJson"
      @click="onClickFieldForPopup"
    >
      <!-- 自定义 label -->
      <template v-if="fieldJson.tip" slot="label">
        <FuFormLabel
          :parent="parent"
          :label="label"
          :field-json="fieldJson"
        />
      </template>
    </Field>

    <Popup
      v-model="visibleForPopup"
      position="bottom"
      round
      get-container="body"
    >
      <!-- 标题 -->
      <p v-if="renderMode != 'picker'" class="select-tit">{{ label }}</p>
      <!-- 搜索框 -->
      <Search
        v-if="fieldJson.search"
        v-model="searchVal"
        :placeholder="$t('placeholderSearch')"
        @input="onSearchInput"
      />

      <!-- 宫格模式 -->
      <Grid
        v-if="renderMode == 'grid'"
        class="fu-min-select-box"
        :column-num="4"
      >
        <GridItem
          v-for="item in fieldJson.options"
          :key="item.label"
          @click="onConfirm(item)"
        >
          <VanImage
            v-if="iconMap"
            fit="contain"
            height="40"
            round
            :src="iconMap[item.label.trim().split(' ').join('-')] || iconMap.default"
          />
          <p v-if="!iconMap || !onlyIcon" style="font-size: 10px; text-align: center">{{ parent.lang !== 'en' ? item.labelLocal || item.label : item.label }}</p>
        </GridItem>
      </Grid>

      <!-- 下拉模式 -->
      <List
        v-else-if="renderMode == 'list'"
        v-model="loading"
        :finished="!loading"
        :loading-text="$t('loading')"
        class="fu-min-select-box"
      >
        <Cell
          v-for="item in fieldJson.options"
          :key="item.label"
          class="fu-select-list-cell"
          :class="{'active': item.value == currentValue}"
          style="text-align: center"
          @click="onConfirm(item)"
        >
          {{ parent.lang !== 'en' ? item.labelLocal || item.label : item.label }}

          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template v-if="item.value == currentValue" #right-icon>
            <Icon name="success" />
          </template>
        </Cell>
      </List>

      <!-- Picker模式 -->
      <Picker
        v-else
        v-model="currentValue"
        show-toolbar
        :value-key="optionsLabel"
        :loading="loading"
        :columns="fieldJson.options"
        item-height="50"

        :confirm-button-text="$t('confirm')"
        :cancel-button-text="$t('cancel')"
        @cancel="visibleForPopup = false"
        @confirm="onConfirm"
      >
        <!-- 配置 Icon -->
        <template
          v-if="iconMap"
          slot="option"
          slot-scope="scope"
        >
          <VanImage
            fit="cover"
            height="40"
            round
            :src="iconMap[scope.label.trim().split(' ').join('-')] || iconMap.default"
          />
          <span v-if="!onlyIcon" style="margin-left: 10px">{{ parent.lang !== 'en' ? scope.labelLocal || scope.label : scope.label }}</span>
        </template>

        <!-- 正常文案展示 -->
        <template
          v-else
          slot="option"
          slot-scope="scope"
        >
          <!-- 自定义内容是为以防内容过长的时候无法查看全部内容 -->
          <NoticeBar
            wrapable
            :scrollable="false"
            style="width: 100%"
            color="#000"
            background="transparent"
            :text="parent.lang !== 'en' ? scope.labelLocal || scope.label : scope.label"
          />
        </template>
      </Picker>
    </Popup>

    <!-- Note 提示组件 -->
    <FuFormNote
      v-if="!parent.isFormReadOnly"
      :lang="parent.lang"
      :field-json="fieldJson"
    />
  </div>
</template>

<script>
import commonMixins from '../mixins/materials'
import displayMixins from '../mixins/display'
import optionsSourceMixins from '../mixins/optionsSource'
import optionsRelyMixins from '../mixins/optionsRely'
import { propEq, find, clone } from 'ramda'
import { NoticeBar, Search, Image as VanImage, Grid, GridItem, List, Cell, Icon } from 'vant'
import { logoMap } from '../utils/codeMap'

export default {
  name: 'FormItemSelect',
  components: { NoticeBar, Search, VanImage, Grid, GridItem, List, Cell, Icon },
  mixins: [commonMixins, displayMixins, optionsSourceMixins, optionsRelyMixins],
  data() {
    return {
      searchVal: '',
      timer: null
    }
  },
  computed: {
    fieldShowValue() {
      const currentOption = find(propEq('value', this.currentValue))(this.fieldJson.options) || {}

      // 详情的时候没有对应值的话 直接显示key
      if (this.parent.isFormReadOnly) {
        const labelPath = this.currentPath.map((key, index) => {
          return index == this.currentPath.length - 1 ? `${key}${this.optionsLabel.replace(this.optionsLabel[0], this.optionsLabel[0].toUpperCase())}` : key
        })
        const label = this.formStore.getFieldValue(labelPath)

        return label || currentOption[this.optionsLabel] || this.currentValue
      }

      return currentOption[this.optionsLabel]
    },
    renderMode() {
      const { renderMode } = this.fieldJson

      return renderMode || 'picker'
    },
    iconMap() {
      const { hasIcon, iconType } = this.fieldJson

      if (!hasIcon || !iconType) { return null }

      return logoMap[iconType] || null
    },
    onlyIcon() {
      return !!this.fieldJson.onlyIcon
    }
  },
  created() {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const { optionsUnit } = this.fieldJson

      // 判断是否有单位
      if (optionsUnit) {
        const copyPath = clone(this.currentPath)
        const prevPath = copyPath.splice(0, copyPath.length - 1)

        const unitPath = [...prevPath, `${this.data.key}Unit`]

        this.formStore.setFieldValue(unitPath, optionsUnit)
      }

      this.fieldJson.cloneOptions = clone(this.fieldJson.options)
    },
    onConfirm(option) {
      if (!option) { return }

      this.currentValue = option.value
      this.visibleForPopup = false

      // 配置了允许自动打开下一个下拉组件的情况下
      this.toggleNextSelect()
    },

    // 自动关联下一个
    toggleNextSelect() {
      // 开关判断
      const { fluency } = this.parent.schema.fieldJson || {}
      if (!fluency) { return }

      const currentUid = this._uid

      let currentIndex = 0
      this.$parent.$children.forEach((item, index) => {
        if (item._uid == currentUid) {
          currentIndex = index
        }
      })

      let nextVom = this.$parent.$children[currentIndex + 1]
      // 如果是最后一个则终止
      if (!nextVom) { return }

      // 循环找出下一个最近非隐藏的兄弟节点
      while (!nextVom.ifRender) {
        currentIndex = currentIndex + 1
        nextVom = this.$parent.$children[currentIndex + 1]
      }

      // 最终判断有下个节点才执行
      if (nextVom) {
        nextVom.visibleForPopup = true
      }
    },

    onSearchInput(val) {
      this.clearTimer()

      this.timer = setTimeout(() => {
        this.handleFilter(val)
      }, 500)
    },
    clearTimer() {
      this.timer && clearTimeout(this.timer)
    },

    // 根据关键字筛选 options
    handleFilter(key) {
      const options = this.fieldJson.cloneOptions
      var filterOptions = []
      const keyLen = key.length

      if (key && keyLen > 0) {
        filterOptions = options.filter(item => {
          const { label, labelLocal } = item

          // IDP-6597 关键词搜索需要从左到右按序匹配
          const currentLabel = this.parent.lang == 'en' ? label : labelLocal

          // 取消大小写区分
          // return currentLabel.substr(0, keyLen).toLowerCase() == key.toLowerCase()
          return currentLabel.toLowerCase().includes(key.toLowerCase())
        })
      } else {
        filterOptions = clone(options)
      }

      this.$set(this.fieldJson, 'options', filterOptions)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep  .van-picker-column__item .van-notice-bar__wrap{
  justify-content: center;
}

.fu-min-select-box {
  // min-height: 30vh;
  min-height: 20vh;
  max-height: 60vh;
}

.select-tit {
  font-size: 12px;
  font-weight: 900;
  padding-left: 10px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.fu-select-list-cell.active {
  color: #ee0a24;
  font-weight: 500;
}
</style>
